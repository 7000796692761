import React, {
  useState, useContext, useMemo, useEffect,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  TbDiscountCheck,
} from 'react-icons/tb';
import {
  Button, Container, Grid,
} from '@mui/material';
import Swal2 from 'sweetalert2';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import './ClientForm.scss';
import { CLIENTS_URL, PROFESSIONS_URL } from '../../Constants/URLS';
import RadioCheckboxButton from '../../Components/RadioCheckboxButton';
import LoadingScreen from '../LoadingScreen';

function ClientForm() {
  const location = useLocation();
  const initialFormValues = location.state?.formState || {};

  const [age, setAge] = useState(initialFormValues.age || '');
  const [numChildren, setNumChildren] = useState(initialFormValues.numChildren || 0);
  const [profession, setProfession] = useState(initialFormValues.profession || '');
  const [selectedGender, setSelectedGender] = useState(initialFormValues.selectedGender || '');
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState(initialFormValues.selectedMaritalStatus || '');
  const [hasChildren, setHasChildren] = useState(initialFormValues.hasChildren !== undefined
    ? initialFormValues.hasChildren : null);
  const [formErrors, setFormErrors] = useState([]);

  const userContext = useContext(UserContext);
  const { user } = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const navigate = useNavigate();

  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  // Get the current time
  const currentTime = new Date().getHours();

  // Determine the appropriate greeting based on the time of day
  let greeting = '';
  if (currentTime >= 5 && currentTime < 12) {
    greeting = 'Goedemorgen';
  } else if (currentTime >= 12 && currentTime < 18) {
    greeting = 'Goedemiddag';
  } else {
    greeting = 'Goedenavond';
  }

  const handleGenderSelect = (gender) => {
    setSelectedGender(gender);
  };

  const handleMaritalStatusSelect = (status) => {
    setSelectedMaritalStatus(status);
  };

  const handleAgeChange = (event) => {
    setAge(event.target.value);
  };

  const handleProfessionChange = (event) => {
    setProfession(event.target.value);
  };

  const handleHasChildrenToggle = (hasKids) => {
    setHasChildren(hasKids);
    if (hasKids) {
      setNumChildren(1);
    } else {
      setNumChildren(0);
    }
  };

  useEffect(() => {
    let newProgress = 0;
    if (age) newProgress += 1;
    if (selectedGender) newProgress += 1;
    if (selectedMaritalStatus) newProgress += 1;
    if (hasChildren !== null) newProgress += 1;
    if (profession) newProgress += 1;
    setProgress(newProgress);
  }, [age, selectedGender, selectedMaritalStatus, hasChildren, profession]);

  const getOrCreateProfession = async (professionName) => {
    if (!professionName) return null;

    try {
      let response = await api.get(`${PROFESSIONS_URL}?job_role=${professionName}`);
      const professions = response.data;
      const existingProfession = professions.find(
        (prof) => prof.job_role.trim().toLowerCase() === professionName.trim().toLowerCase(),
      );

      if (existingProfession) {
        return existingProfession.id;
      }

      // Try to create a new profession
      response = await api.post(PROFESSIONS_URL, { job_role: professionName });
      return response.data.id; // Return new profession ID
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error handling profession:', error);
      // If the error status is 400, change the alert message to indicate invalid profession input
      if (error.response && error.response.status === 400) {
        return 'invalid';
      }
      throw error;
    }
  };

  const disableInputScroll = (event) => {
    event.target.blur();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setFormErrors([]); // Clear form errors

    if (numChildren === '' || numChildren < 0 || numChildren === null) {
      Swal2.fire({
        icon: 'error',
        title: 'Error',
        text: 'Vul een geldig aantal kinderen in.',
      });
      setLoading(false);
      return;
    }

    // Function to check for missing fields and return their names
    const getMissingFields = () => {
      const missingFields = [];
      if (!age) missingFields.push('leeftijd');
      if (!selectedGender) missingFields.push('geslacht');
      if (!selectedMaritalStatus) missingFields.push('burgerlijke staat');
      if (hasChildren === null) missingFields.push('kinderen');
      if (!profession) missingFields.push('beroep');
      return missingFields;
    };

    const missingFields = getMissingFields();
    setFormErrors(missingFields);

    if (missingFields.length > 0) {
      const missingFieldsMessage = missingFields.join(', ');
      Swal2.fire({
        icon: 'error',
        title: 'Error',
        text: `Vul de volgende verplichte velden in: ${missingFieldsMessage}.`,
      });
      setLoading(false);
      return;
    }

    let professionId = null;
    try {
      const professionResult = await getOrCreateProfession(profession);

      if (professionResult === 'invalid') {
        Swal2.fire({
          icon: 'error',
          title: 'Error',
          text: 'Voer een geldig beroep in.',
        });
        setLoading(false);
        return;
      }

      professionId = professionResult;
    } catch (error) {
      Swal2.fire({
        icon: 'error',
        title: 'Error',
        text: 'Er is een fout opgetreden bij het verwerken van het beroep.',
      });
      setLoading(false);
      return;
    }

    const clientData = {
      age,
      gender: selectedGender,
      marital_status: selectedMaritalStatus,
      num_children: numChildren,
      occupation: professionId,
    };

    try {
      const response = await api.post(CLIENTS_URL, clientData);
      navigate('/klant-match', { state: { clientId: response.data.id } });
    } catch (error) {
      let text = 'Er is een fout opgetreden bij het maken van de client.';
      if (error.response.data.age) {
        setFormErrors([...formErrors, 'age']);
        text = 'Er is een fout opgetreden bij het maken van de client. Vul een geldige leeftijd in.';
      }
      Swal2.fire({
        icon: 'error',
        title: 'Error',
        text,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="progress-bar">
            <div
              className="progress-bar-progress"
              style={{
                width: `${(progress / 5) * 100}%`,
              }}
            />
          </div>
          <Container sx={{ py: 9, maxWidth: '700px !important' }}>
            <div className="client-form">
              <div className="panel client-form-panel">
                <h1>
                  {greeting}
                  {' '}
                  {user.first_name}
                  ! Ik heb wat informatie nodig om het beste AO-praktijkvoorbeeld
                  voor je klant te vinden.
                </h1>
              </div>

              <div className={`panel client-form-panel ${formErrors.includes('leeftijd') ? 'error' : ''}`}>
                <label>
                  Wat is de leeftijd van je klant?
                  <span className="required">*</span>
                </label>
                <input
                  type="number"
                  onWheel={disableInputScroll}
                  onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                  placeholder="Bijv: 33"
                  value={age}
                  onChange={handleAgeChange}
                  min={18}
                  max={70}
                />
              </div>

              <div className={`panel client-form-panel ${formErrors.includes('geslacht') ? 'error' : ''}`}>
                <label>
                  Wat is het geslacht van je klant?
                  <span className="required">*</span>
                </label>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <RadioCheckboxButton
                      handleSelect={() => handleGenderSelect('Man')}
                      value="Man"
                      selected={selectedGender === 'Man'}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <RadioCheckboxButton
                      value="Vrouw"
                      selected={selectedGender === 'Vrouw'}
                      handleSelect={() => handleGenderSelect('Vrouw')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <RadioCheckboxButton
                      value="Anders"
                      selected={selectedGender === 'Anders'}
                      handleSelect={() => handleGenderSelect('Anders')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </div>

              <div className={`panel client-form-panel ${formErrors.includes('burgerlijke staat') ? 'error' : ''}`}>
                <label>
                  Wat is de burgerlijke staat van je klant?
                  <span className="required">*</span>
                </label>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <RadioCheckboxButton
                      value="Getrouwd"
                      selected={selectedMaritalStatus === 'Getrouwd'}
                      handleSelect={() => handleMaritalStatusSelect('Getrouwd')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <RadioCheckboxButton
                      value="Geregistreerd partnerschap"
                      selected={selectedMaritalStatus === 'Geregistreerd partnerschap'}
                      handleSelect={() => handleMaritalStatusSelect('Geregistreerd partnerschap')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <RadioCheckboxButton
                      value="Gescheiden"
                      selected={selectedMaritalStatus === 'Gescheiden'}
                      handleSelect={() => handleMaritalStatusSelect('Gescheiden')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <RadioCheckboxButton
                      value="Weduwe/Weduwnaar"
                      selected={selectedMaritalStatus === 'Weduwe/Weduwnaar'}
                      handleSelect={() => handleMaritalStatusSelect('Weduwe/Weduwnaar')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <RadioCheckboxButton
                      value="Samenwonend"
                      selected={selectedMaritalStatus === 'Samenwonend'}
                      handleSelect={() => handleMaritalStatusSelect('Samenwonend')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <RadioCheckboxButton
                      value="Alleenstaand"
                      selected={selectedMaritalStatus === 'Alleenstaand'}
                      handleSelect={() => handleMaritalStatusSelect('Alleenstaand')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </div>

              <div className={`panel client-form-panel ${formErrors.includes('kinderen') ? 'error' : ''}`}>
                <label>
                  Heeft je klant kinderen?
                  <span className="required">*</span>
                </label>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <RadioCheckboxButton
                      value="Ja"
                      selected={hasChildren === true} // strict comparison to true
                      handleSelect={() => handleHasChildrenToggle(true)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <RadioCheckboxButton
                      value="Nee"
                      selected={hasChildren === false} // strict comparison to false
                      handleSelect={() => handleHasChildrenToggle(false)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                {hasChildren && (
                <>
                  <label style={{ fontSize: 16, marginTop: 20, marginBottom: 10 }}>
                    Hoeveel kinderen heeft je klant?
                    <span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    onWheel={disableInputScroll}
                    onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                    placeholder="Bijv: 2"
                    value={numChildren}
                    onChange={(e) => setNumChildren(e.target.value)}
                    min={0}
                    max={10}
                  />
                </>
                )}
              </div>

              <div className={`panel client-form-panel ${formErrors.includes('beroep') ? 'error' : ''}`}>
                <label>
                  Wat is het beroep van je klant?
                  <span className="required">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Bijv: Docent"
                  value={profession}
                  onChange={handleProfessionChange}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleSubmit(event);
                    }
                  }}
                />
              </div>

              <div style={{ textAlign: 'right' }}>
                <Button
                  className="btn btn-green"
                  onClick={handleSubmit}
                  startIcon={<TbDiscountCheck />}
                >
                  Vind een risicomatch
                </Button>
              </div>
            </div>
          </Container>
        </>
      )}
    </>
  );
}

export default ClientForm;
