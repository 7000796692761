import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import './ConfirmEmail.scss';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import apiUtils from '../../Utils/ApiUtils';
import { EMAIL_CONFIRM_URL } from '../../Constants/URLS';

function ConfirmEmail() {
  const { key } = useParams();
  const api = apiUtils();
  const navigate = useNavigate();

  useEffect(() => {
    if (!key) return;
    api.postNoAuth(EMAIL_CONFIRM_URL, { key }).then(() => {
      Swal.fire({
        title: 'Email bevestigd',
        text: 'Je email is bevestigd. Je kunt nu inloggen.',
        icon: 'success',
      }).then(() => {
        navigate('/inloggen');
      });
    }).catch(() => {
      Swal.fire({
        title: 'Fout',
        text: 'Er is iets fout gegaan bij het bevestigen van je email.',
        icon: 'error',
      }).then(() => {
        navigate('/');
      });
    });
  }, [key]);

  return (
    <div className="authentication-view">
      <Container maxWidth="lg" sx={{ pt: 5, pb: 15 }}>
        <a href="/">
          <img src="/default-platform-logo.png" alt="logo" className="logo" />
        </a>
      </Container>
      <Container maxWidth="sm" sx={{ mb: 9 }}>
        <div className="panel login-form">
          <h1>
            Bevestig
            {' '}
            <span>Email</span>
            .
          </h1>
        </div>
      </Container>
    </div>
  );
}

export default ConfirmEmail;
