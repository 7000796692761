import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TablePagination,
  TableSortLabel, Container,
  Button, Tooltip, Box,
} from '@mui/material';
import { IconPlus, IconTags } from '@tabler/icons-react';
import moment from 'moment/moment';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { TOPICS_URL } from '../../../../Constants/URLS';
import {
  containerStyles, tablePaginationStyles,
} from '../../../../Components/TableStyles/TableStyles';
import './TopicsTableView.scss';
import BackofficeTitle from '../../../../Components/Backoffice/BackofficeTitle';

function TopicsTableView() {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const [topics, setTopics] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [search, setSearch] = useState('');
  const [totalCount, setTotalCount] = useState(0);

  const fetchTopics = async () => {
    const response = await api.get(TOPICS_URL, {
      params: {
        page,
        page_size: rowsPerPage,
        ordering: `${order === 'desc' ? '-' : ''}${orderBy}`,
        search,
      },
    });
    setTopics(response.data.results);
    setTotalCount(response.data.count);
  };

  const handleRequestSort = (property) => {
    if (property === orderBy) {
      if (order === 'asc') {
        setOrder('desc');
      } else {
        setOrderBy('');
      }
    } else {
      setOrderBy(property);
      setOrder('asc');
    }
  };

  useEffect(() => {
    fetchTopics();
  }, [page, rowsPerPage, order, orderBy, search]);

  useEffect(() => {
    setPage(1);
  }, [search, rowsPerPage, order, orderBy, search]);

  return (
    <div className="topics-view">
      <Container maxWidth="lg" sx={containerStyles}>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            mb: 2,
          }}
        >
          <BackofficeTitle title="Onderwerpen" icon={<IconTags />} />
          <input
            placeholder="Zoek..."
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            className="backoffice-search"
          />
          <Button
            onClick={() => navigate('/backoffice/onderwerpen/toevoegen')}
            startIcon={<IconPlus />}
            className="btn btn-green"
          >
            Nieuw onderwerp
          </Button>
        </Box>
        <TableContainer className="backoffice-table">
          <Table>
            <TableHead className="table-header">
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order : 'asc'}
                    onClick={() => handleRequestSort('name')}
                  >
                    Naam
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'description'}
                    direction={orderBy === 'description' ? order : 'asc'}
                    onClick={() => handleRequestSort('description')}
                  >
                    Beschrijving
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  Gemaakt op
                </TableCell>
                <TableCell>
                  Gemaakt door
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {topics.length === 0 && (
                <TableRow className="no-results-table-row">
                  <TableCell colspan={4}>
                    Geen resultaten gevonden
                  </TableCell>
                </TableRow>
              )}
              {topics.map((topic) => (
                <TableRow
                  key={topic.id}
                  className="table-row"
                  hover
                  onClick={() => navigate(`/backoffice/onderwerpen/${topic.id}`)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell className="table-cell">{topic.name}</TableCell>
                  <Tooltip title={topic.description} placement="top" arrow>
                    <TableCell className="table-cell">
                      <span>
                        {topic.description.slice(0, 40)}
                        ...
                      </span>
                    </TableCell>
                  </Tooltip>
                  <TableCell className="light">
                    {moment(topic.created_at).format('DD MMMM YYYY, HH:mm')}
                  </TableCell>
                  <TableCell>
                    {topic.created_by}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalCount}
          page={page - 1}
          onPageChange={(event, newPage) => setPage(newPage + 1)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(1);
          }}
          labelRowsPerPage="Rijen per pagina:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} van ${count !== -1 ? count : `meer dan ${to}`}`}
          style={tablePaginationStyles}
        />
      </Container>
    </div>
  );
}

export default TopicsTableView;
