/* eslint-disable max-len */
import React, { useContext } from 'react';
import { Container, Grid, Button } from '@mui/material';
import { TbFaceId } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { IconZoom } from '@tabler/icons-react';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import { useMatches } from '../../Providers/MatchesProvider/MatchesProvider';
import './HomePage.scss';
import FoundMatch from '../../Components/FoundMatch';

function HomePage() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { matches } = useMatches();

  const goToClientForm = () => {
    navigate('/klant-formulier');
  };

  // Get the current time
  const currentTime = new Date().getHours();

  // Determine the appropriate greeting based on the time of day
  let greeting = '';
  if (currentTime >= 5 && currentTime < 12) {
    greeting = 'Goedemorgen';
  } else if (currentTime >= 12 && currentTime < 18) {
    greeting = 'Goedemiddag';
  } else {
    greeting = 'Goedenavond';
  }
  return (
    <Container maxWidth="lg" sx={{ pt: 6, pb: 9, minHeight: 'calc(100vh - 130px)' }}>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={6}>
          <div className="panel" style={{ marginBottom: 40 }}>
            <h2>Vind een risicomatch</h2>
            <p>
              {greeting}
              {' '}
              {user.first_name}
              !
              <br />
              <br />
              Aan de hand van enkele vragen vind jij het beste praktijkvoorbeeld die
              arbeidsongeschiktheidsrisico&apos;s voor jouw klant tastbaar maakt.
            </p>
            <Button
              onClick={goToClientForm}
              className="btn btn-green"
              startIcon={<IconZoom />}
              sx={{ mt: 2 }}
            >
              Vind een risicomatch
            </Button>
          </div>
          <div className="panel">
            <img src="/moving-boxes.jpg" alt="Moving boxes" style={{ marginBottom: 30 }} />
            <p>
              De gevolgen van arbeidsongeschiktheid, overlijden en werkloosheid op het inkomen zijn groot.
              Deze inkomensrisico&apos;s zijn echter lang niet altijd te overzien en vaak ingewikkeld voor klanten om te begrijpen.
              <br />
              <br />
              Met het AO Platform willen we jou als adviseur helpen om het arbeidsongeschiktheidsrisico op
              een begrijpelijke en toegankelijke manier voor je klant inzichtelijk te maken. Ieder adviesgesprek weer!
              <br />
              <br />
              Doe je voordeel met praktijkcases via de AO-Risico match, maak het inkomensgat inzichtelijk met de inkomensplanner
              of gebruik slimme handvatten uit onze AO-toolkit.
              <br />
              <br />
              Over initiatiefnemer Credit Life:
              <br />
              <br />
              Als dé verzekeraar bij arbeidsongeschiktheid, werkloosheid en overlijden is Credit Life van mening dat eigenlijk
              niemand in Nederland in deze tijd nog zonder inkomensverzekeringen kan. Zeker niet als iemand een grote,
              langdurige financiële verplichting zoals een hypotheek aangaat.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className="heading" style={{ marginTop: 35 }}>
            <TbFaceId />
            <h1>Gevonden risicomatches</h1>
          </div>
          <div className="matches-list">
            {matches.slice(0, 5).map((match, index) => (
              <FoundMatch match={match} key={index} />
            ))}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default HomePage;
