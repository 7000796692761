import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import './CaseStudyForm.scss';
import {
  Button, Grid,
} from '@mui/material';
import {
  TbFileSearch, TbEditCircle, TbDeviceFloppy, TbFolderPlus,
} from 'react-icons/tb';
import { IconLoader2 } from '@tabler/icons-react';
import { Editor } from '@tinymce/tinymce-react';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import getEditorApiKey from '../../Utils/TinymceUtils';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { CASE_STUDY_IMAGES_URL } from '../../Constants/URLS';

const editorApiKey = getEditorApiKey();

function CaseStudyForm({
  newCaseStudy,
  handleInputChange,
  handleSubmit,
  story,
  handlePreviewPdf,
  rewriteStoryAndHeadline,
  handleGenerateCases,
  showGenerateCasesButton,
  professionId,
  notifyChanges,
  updateCurrentStory,
}) {
  const [localStory, setLocalStory] = useState(story);
  const [availableImages, setAvailableImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);

  // Function to strip HTML tags and count characters
  const countCharactersWithoutHtml = (htmlContent) => {
    const textContent = htmlContent.replace(/<[^>]*>/g, '');
    return textContent.length;
  };
  const [charCount, setCharCount] = useState(countCharactersWithoutHtml(story));

  const fetchAvailableImages = async () => {
    try {
      const response = await api.get(CASE_STUDY_IMAGES_URL);
      setAvailableImages(response.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch images', error);
    }
  };

  const handleImageChange = (e) => {
    const { value } = e.target;
    // Assuming you store the image ID in the value attribute of each option
    handleInputChange({ target: { name: 'image_link', value } });
    notifyChanges(true);
  };

  // Update local state when props change
  useEffect(() => {
    setLocalStory(story);
    setCharCount(countCharactersWithoutHtml(story));
    if (updateCurrentStory) {
      updateCurrentStory(story);
    }
    fetchAvailableImages();
  }, [story]);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [newCaseStudy]);

  const handleEditorChange = (content, editor) => {
    if (editor.id === 'story-editor') {
      setLocalStory(content);
      setCharCount(countCharactersWithoutHtml(content));
      notifyChanges(true);
      // Update the parent component with current story content
      if (updateCurrentStory) {
        updateCurrentStory(content);
      }
    }
  };

  const changeSalary = (e) => {
    // Remove non-numeric characters and limit length
    const cleanValue = e.target.value.replace(/\D/g, '');
    handleInputChange({ target: { name: 'salary', value: cleanValue } });
    notifyChanges(true);
  };

  // Wrapper around handleInputChange to notify parent about changes
  const wrappedHandleInputChange = (e) => {
    handleInputChange(e);
    notifyChanges(true);
  };

  // Wrapper around handleSubmit to notify parent that changes are saved
  const wrappedHandleSubmit = () => {
    handleSubmit(localStory);
    notifyChanges(false);
  };

  const wrappedRewriteStoryAndHeadline = (caseStudy) => {
    setLoading(true);
    rewriteStoryAndHeadline(caseStudy);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <label>
            Afbeelding
            <span className="required">*</span>
          </label>
          <select
            name="image_link"
            value={newCaseStudy.image_link}
            onChange={handleImageChange}
          >
            <option value="">Selecteer een afbeelding</option>
            {availableImages.map((image) => (
              <option key={image.id} value={image.id}>
                {image.gender}
                {' - '}
                {image.ethnicity}
                {' - '}
                {image.age_min}
                :
                {image.age_max}
              </option>
            ))}
          </select>
        </Grid>
        <Grid item xs={12} lg={4}>
          <label>
            Naam
            <span className="required">*</span>
          </label>
          <input
            type="text"
            placeholder="Naam"
            name="first_name"
            value={newCaseStudy.first_name}
            onChange={wrappedHandleInputChange}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <label>
            Beroep
            <span className="required">*</span>
          </label>
          <input
            type="text"
            placeholder="Beroep"
            name="occupation"
            value={newCaseStudy.occupation && newCaseStudy.occupation.job_role ? newCaseStudy.occupation.job_role : ''}
            onChange={wrappedHandleInputChange}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <label>
            Geslacht
            <span className="required">*</span>
          </label>
          <select
            name="gender"
            value={newCaseStudy.gender}
            onChange={wrappedHandleInputChange}
          >
            <option value="">Selecteer geslacht</option>
            <option value="Man">Man</option>
            <option value="Vrouw">Vrouw</option>
            <option value="Anders">Anders</option>
            <option value="Privé">Privé</option>
          </select>
        </Grid>
        <Grid item xs={12} lg={12}>
          <label>
            Koptekst
            <span className="required">*</span>
          </label>
          <input
            type="text"
            placeholder="Koptekst"
            name="headline"
            value={newCaseStudy.headline}
            onChange={wrappedHandleInputChange}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <label>
            Verwachte bruto maandsalaris
            <span className="required">*</span>
          </label>
          <input
            type="text"
            placeholder="Verwachte bruto maandsalaris"
            name="salary"
            value={newCaseStudy.salary ? new Intl.NumberFormat('nl-NL').format(newCaseStudy.salary) : ''}
            onChange={changeSalary}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <label>
            Voorbeeldcase
            <span className="required">*</span>
          </label>
          <Editor
            id="story-editor"
            apiKey={editorApiKey}
            init={{
              plugins: 'lists',
              toolbar: 'undo redo | bold italic underline | numlist bullist | indent outdent | removeformat',
              menubar: false,
            }}
            initialValue={story}
            onEditorChange={handleEditorChange}
          />
          <div className="char-counter" style={{ color: charCount > 720 ? '#F45051' : 'inherit' }}>
            Aantal tekens:
            {' '}
            {charCount}
            /720
          </div>
        </Grid>
      </Grid>
      <div className="button-container">
        {showGenerateCasesButton && (
          <Button
            onClick={() => handleGenerateCases(newCaseStudy.id, professionId)}
            startIcon={<TbFolderPlus />}
            className="btn btn-green"
            sx={{ mt: 3 }}
          >
            Cases genereren
          </Button>
        )}
        <Button
          onClick={() => wrappedHandleSubmit()}
          startIcon={<TbDeviceFloppy />}
          className="btn btn-green"
          sx={{ mt: 3 }}
        >
          Opslaan
        </Button>
        <Button
          onClick={() => handlePreviewPdf(newCaseStudy.id, localStory)}
          className="btn btn-green"
          sx={{ mt: 3 }}
          startIcon={<TbFileSearch />}
        >
          PDF
        </Button>
        <Button
          onClick={() => wrappedRewriteStoryAndHeadline(newCaseStudy)}
          startIcon={loading ? <IconLoader2 className="loader" /> : <TbEditCircle />}
          disabled={loading}
          className="btn btn-green"
          sx={{ mt: 3 }}
        >
          Herschrijf met AI
        </Button>
      </div>
    </>
  );
}

CaseStudyForm.propTypes = {
  newCaseStudy: PropTypes.shape({
    first_name: PropTypes.string,
    id: PropTypes.string,
    age: PropTypes.number,
    gender: PropTypes.string,
    occupation: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        job_role: PropTypes.string,
      }),
    ]),
    headline: PropTypes.string,
    image_link: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        image: PropTypes.string,
        id: PropTypes.string,
      }),
    ]),
    salary: PropTypes.number,
    story: PropTypes.string,
  }).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  story: PropTypes.string,
  handlePreviewPdf: PropTypes.func,
  handleGenerateCases: PropTypes.func,
  rewriteStoryAndHeadline: PropTypes.func.isRequired,
  showGenerateCasesButton: PropTypes.bool,
  professionId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  notifyChanges: PropTypes.func,
  updateCurrentStory: PropTypes.func,
};

CaseStudyForm.defaultProps = {
  story: '',
  handlePreviewPdf: () => { },
  handleGenerateCases: () => { },
  showGenerateCasesButton: false,
  professionId: null,
  notifyChanges: () => { },
  updateCurrentStory: null,
};

export default CaseStudyForm;
