import React, { useContext, useEffect, useState } from 'react';
import {
  Box, Button, Chip, Container, Grid, Tooltip,
} from '@mui/material';
import {
  IconArchive, IconArchiveFilled,
  IconBook, IconChevronDown, IconFileText, IconInfoCircle, IconLoader2,
  IconPencil, IconRefresh, IconTrash, IconX, IconPlus,
} from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import BackofficeTitle from '../../../../Components/Backoffice/BackofficeTitle';
import FormPanel from '../../../../Components/Backoffice/FormPanel';
import FormLabel from '../../../../Components/Backoffice/FormLabel';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { AUDIT_LOG_URL, SOURCES_URL, TOPICS_URL } from '../../../../Constants/URLS';
import ModerationHistory from '../../../../Components/Backoffice/ModerationHistory';
import FormInput from '../../../../Components/Backoffice/FormInput';
import OptionsPopover from '../../../../Components/Backoffice/OptionsPopover';
import StatusSwitch from '../../../../Components/Backoffice/StatusSwitch';
import TopicSelectionModal from '../../../../Components/Backoffice/TopicSelectionModal';
import FormTextArea from '../../../../Components/Backoffice/FormTextArea';

function SourceView() {
  const [source, setSource] = useState(null);
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const { id } = useParams();
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rescrapeLoading, setRescrapeLoading] = useState(false);
  const [auditLogs, setAuditLogs] = useState([]);
  const [editText, setEditText] = useState(false);
  const [editTextValue, setEditTextValue] = useState('');

  const truncateString = (text, maxLen) => {
    if (text.length < maxLen) return text;
    return `${text.slice(0, maxLen)}...`;
  };

  const startEditText = () => {
    setEditTextValue(source.additional_text);
    setEditText(true);
  };

  const fetchSource = () => {
    api.get(SOURCES_URL + id).then((response) => {
      setSource(response.data);
      setSelectedTopics(response.data.topics);
    }).catch(() => {
      navigate('/backoffice/bronnen/');
    });
  };

  const fetchAuditLogs = () => {
    api.get(`${AUDIT_LOG_URL}?source=${id}`).then((response) => {
      setAuditLogs(response.data);
    });
  };

  const saveEditText = async () => {
    const swal = await Swal.fire({
      title: 'Weet je zeker dat je de tekst wilt wijzigen?',
      // this action will make the source require two new checks
      text: 'Deze actie zal de bron opnieuw laten controleren.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ja, wijzigen',
      cancelButtonText: 'Annuleren',
    });
    if (!swal.isConfirmed) return;

    api.patch(`${SOURCES_URL}${id}/`, {
      additional_text: editTextValue,
    }).then(() => {
      fetchSource();
      setEditText(false);
    });
  };

  useEffect(() => {
    if (source) {
      setEditTextValue(source.additional_text);
    }
  }, [source]);

  useEffect(() => {
    fetchAuditLogs();
  }, [id]);

  useEffect(() => {
    fetchSource();
  }, []);

  const onDelete = async () => {
    const swal = await Swal.fire({
      title: 'Weet je zeker dat je deze bron wilt verwijderen?',
      text: 'Deze actie kan niet ongedaan worden gemaakt.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ja, verwijderen',
      cancelButtonText: 'Annuleren',
    });

    if (swal.isConfirmed) {
      api.del(`${SOURCES_URL}${id}/`).then(() => {
        Swal.fire('Bron verwijderd', '', 'success');
        navigate('/backoffice/bronnen/');
      });
    }
  };

  const [edit, setEdit] = useState(false);

  // Topics utils
  const [topics, setTopics] = useState([]);
  const topicsValue = selectedTopics.map((topic) => topic.id);
  const [topicsSearch, setTopicsSearch] = useState('');
  const [topicsCount, setTopicsCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const topicsMenuOpen = Boolean(anchorEl);
  const topicsOptions = topics.map((topic) => ({
    text: topic.name,
    value: topic.id,
  }));
  const [generatedTopics, setGeneratedTopics] = useState([]);

  const handleTopicClick = (tId) => {
    if (selectedTopics.find((t) => t.id === tId)) {
      setSelectedTopics(selectedTopics.filter((t) => t.id !== tId));
    } else {
      setSelectedTopics([...selectedTopics, topics.find((t) => t.id === tId)]);
    }
  };
  const openMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const removeTopic = (e, tId) => {
    e.stopPropagation();
    setSelectedTopics(selectedTopics.filter((t) => t.id !== tId));
  };

  // Fetch topics
  useEffect(() => {
    const fetchTopics = async () => {
      const rsp = await api.get(TOPICS_URL, {
        params: {
          search: topicsSearch,
        },
      });
      setTopics(rsp.data.results);
      setTopicsCount(rsp.data.count);
    };

    fetchTopics();
  }, [topicsSearch]);

  const onEditSave = () => {
    if (!source.title) {
      Swal.fire({
        title: 'Fout',
        text: 'Titel is verplicht',
        icon: 'error',
      });
      return;
    }
    const data = {
      title: source.title,
      topics: selectedTopics.map((t) => t.id),
    };

    api.put(`${SOURCES_URL}${id}/`, data).then(() => {
      setEdit(false);
      fetchSource();
    });
  };

  const toggleOnline = () => {
    api.patch(`${SOURCES_URL}${source.id}/`, {
      online: !source.online,
    }).then((r) => {
      setSource({ ...source, online: r.data.online });
      fetchAuditLogs();
    });
  };

  const toggleArchived = () => {
    api.patch(`${SOURCES_URL}${source.id}/`, {
      archived: !source.archived,
    }).then((r) => {
      setSource({ ...source, archived: r.data.archived });
      fetchAuditLogs();
    });
  };

  const regenerateTopics = async () => {
    try {
      setLoading(true);
      const response = await api.post(`${SOURCES_URL}${id}/regenerate_topics/`);
      const suggestedTopics = response.data.suggested_topics;
      // with selected topics no duplicates
      const newTopics = [
        ...selectedTopics,
        ...suggestedTopics.filter((topic) => !selectedTopics.map((t) => t.id).includes(topic.id)),
      ];

      setGeneratedTopics(newTopics);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const rescrapeSource = async () => {
    try {
      setRescrapeLoading(true);
      const response = await api.post(`${SOURCES_URL}${id}/rescrape/`);
      setSource(response.data);
    } catch (error) {
      if (error.response?.data?.source_file || error.response?.data?.link) {
        // Handle duplicate file or scraping error specifically
        const message = error.response?.data?.source_file || error.response?.data?.link;
        Swal.fire({
          icon: 'warning',
          title: 'Let op',
          text: message,
          confirmButtonText: 'OK',
          showCancelButton: true,
          cancelButtonText: 'Ga naar bronnen',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
        }).then((result) => {
          if (!result.isConfirmed) {
            navigate('/backoffice/bronen');
          }
        });
      } else {
        // Handle other errors
        Swal.fire({
          icon: 'error',
          title: 'Oeps...',
          text: 'Er is iets misgegaan bij het ophalen van de informatie.',
        });
      }
    } finally {
      setRescrapeLoading(false);
    }
  };

  const closeModal = async (reload) => {
    setGeneratedTopics([]);

    if (reload) {
      window.location.reload();
    }
  };

  return source && (
    <Container sx={{ my: 6 }} className="source-view">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 3,
        }}
      >
        <BackofficeTitle title="Bron Bekijken" icon={<IconBook />} />
        {source.archived && <Chip className="archived-chip" label="Gearchiveerd" />}
        <Button
          onClick={toggleArchived}
          className={`btn btn-icon ${source.archived ? 'btn-warning' : 'btn-ghost'}`}
        >
          {source.archived ? <IconArchiveFilled /> : <IconArchive />}
        </Button>
        <Button onClick={onDelete} className="btn btn-icon btn-ghost">
          <IconTrash />
        </Button>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <StatusSwitch
            setStatus={toggleOnline}
            status={source.online}
            disabled={source.status !== 'approved' || source.archived}
          />
          {source.status !== 'approved' && (
            <Tooltip
              title="U kunt de onlinestatus van een bron alleen wijzigen als deze twee controles heeft doorstaan."
              placement="bottom"
              arrow
            >
              <IconInfoCircle className="online-info" />
            </Tooltip>
          )}
        </Box>
      </Box>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={8}>
          <FormPanel title="Algemeen" style={{ marginTop: 30 }}>
            <FormLabel label="Titel" />
            {edit ? (
              <FormInput
                value={source.title}
                onChange={(value) => setSource({ ...source, title: value })}
                placeholder="Voer de titel van de bron in..."
                limit={225}
              />
            ) : source.title}
            <FormLabel label="Onderwerpen" style={{ marginTop: 20 }} />
            {edit ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {/* eslint-disable-next-line */}
                  <div className="topics-input" onClick={openMenu}>
                    {selectedTopics.map((topic) => (
                      // eslint-disable-next-line
                      <span className="chip" onClick={(e) => removeTopic(e, topic.id)}>
                        {topic.name}
                        <IconX />
                      </span>
                    ))}
                    {selectedTopics.length === 0 && (
                      <span className="placeholder">
                        -- Selecteer onderwerpen --
                      </span>
                    )}
                    <IconChevronDown className="chevron" />
                  </div>
                  <Tooltip
                    title="Voeg een nieuw onderwerp toe."
                    placement="bottom"
                    arrow
                  >
                    <Button href="/backoffice/onderwerpen/toevoegen" target="_blank" className="btn btn-secondary btn-icon">
                      <IconPlus />
                    </Button>
                  </Tooltip>
                </Box>
                <OptionsPopover
                  options={topicsOptions}
                  value={topicsValue}
                  onChange={handleTopicClick}
                  search={topicsSearch}
                  onSearchChange={setTopicsSearch}
                  count={topicsCount}
                  anchorEl={anchorEl}
                  open={topicsMenuOpen}
                  closeMenu={() => setAnchorEl(null)}
                />
              </>
            ) : (
              <>
                {source.topics.map((topic) => (
                  <Chip className="topic-chip" label={topic.name} key={topic.id} sx={{ mr: 1, mb: 1 }} />
                ))}
                {source.topics.length === 0 && '-- Geen onderwerpen geselecteerd --'}
              </>
            )}
            <Box sx={{ mt: 2 }}>
              {edit ? (
                <>
                  <Button
                    className="btn btn-green"
                    onClick={onEditSave}
                  >
                    Opslaan
                  </Button>
                  <Button
                    className="btn btn-ghost"
                    onClick={() => {
                      fetchSource();
                      setEdit(false);
                    }}
                    sx={{ ml: 1 }}
                  >
                    Annuleren
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="btn btn-secondary"
                    onClick={() => setEdit(true)}
                    startIcon={<IconPencil />}
                  >
                    Bewerken
                  </Button>
                  <Button
                    className="btn btn-ghost"
                    onClick={regenerateTopics}
                    startIcon={loading ? <IconLoader2 className="loader" /> : <IconRefresh />}
                    disabled={(loading || rescrapeLoading)}
                    sx={{ ml: 1 }}
                  >
                    Opnieuw onderwerpen genereren
                  </Button>
                </>
              )}
            </Box>
          </FormPanel>
          <FormPanel title="Bron" style={{ marginTop: 30 }}>
            <FormLabel label="Bestand" />
            {source.source_file ? (
              <a className="uploaded-file" href={source.source_file} target="_blank" rel="noreferrer">
                <div className="icon">
                  <IconFileText />
                </div>
                <h4>
                  {truncateString(source.source_file.split('/').pop(), 60)}
                </h4>
              </a>
            ) : '-- Geen bestand --'}
            <FormLabel style={{ marginTop: 20 }} label="Link" />
            {source.web_link ? (
              <a href={source.web_link} target="_blank" rel="noreferrer">{source.web_link}</a>) : '-- Geen link  --'}
            {source.web_link && (
              <Button
                className="btn btn-ghost"
                onClick={rescrapeSource}
                startIcon={rescrapeLoading ? <IconLoader2 className="loader" /> : <IconRefresh />}
                disabled={(loading || rescrapeLoading)}
                sx={{ display: 'flex', mt: 2 }}
              >
                Gegevens opnieuw inlezen
              </Button>
            )}
            <FormLabel style={{ marginTop: 20 }} label="Extra tekst" />
            <small style={{ display: 'block', marginBottom: 10 }}>
              Extra tekst kan gebruikt worden om losse geschreven informatie als bron toe te voegen,
              of om een geüploade bron van extra informatie te voorzien.
            </small>
            {editText ? (
              <>
                <FormTextArea onChange={setEditTextValue} value={editTextValue} rows={5} />
                <Button
                  className="btn btn-green"
                  onClick={saveEditText}
                  sx={{ mt: 2 }}
                >
                  Opslaan
                </Button>
                <Button
                  className="btn btn-ghost"
                  onClick={() => setEditText(false)}
                  sx={{ ml: 1, mt: 2 }}
                >
                  Annuleren
                </Button>
              </>
            ) : (
              <>
                {source.additional_text || '-- Geen tekst --'}
                <Button
                  className="btn btn-secondary"
                  onClick={startEditText}
                  startIcon={<IconPencil />}
                  sx={{ display: 'flex', mt: 2 }}
                >
                  Bewerken
                </Button>
              </>
            )}
          </FormPanel>
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormPanel title="Controle" style={{ marginTop: 30 }}>
            <ModerationHistory
              moderationHistory={source.moderation_history}
              createdAt={source.created_at}
              createdBy={source.created_by}
              status={source.status}
              contentType="source"
              objectId={source.id}
              refresh={fetchSource}
              auditLogs={auditLogs}
              disabled={source.archived}
            />
          </FormPanel>
        </Grid>
      </Grid>
      <TopicSelectionModal
        topics={generatedTopics}
        selectedTopicsInput={selectedTopics}
        sourceId={id}
        onClose={closeModal}
      />
    </Container>
  );
}

export default SourceView;
