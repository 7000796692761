import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IconLoader2,
  IconBook,
  IconEdit,
  IconTrash,
} from '@tabler/icons-react';
import {
  Container,
  Backdrop,
  Button,
  CircularProgress,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import Swal from 'sweetalert2';
import BackofficeTitle from '../../../../Components/Backoffice/BackofficeTitle';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { TEST_CASES_URL, GENERATE_TEST_ANSWER } from '../../../../Constants/URLS';

function TestCaseDetailView() {
  // Utils
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const navigate = useNavigate();
  const { id } = useParams();

  // Form states
  const [question, setQuestion] = useState('');
  const [expectedAnswer, setExpectedAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [saving, setSaving] = useState(false);
  const [running, setRunning] = useState(false);
  const [deleting, setDeleting] = useState(false);

  // Edit states
  const [editingQuestion, setEditingQuestion] = useState(false);
  const [editingAnswer, setEditingAnswer] = useState(false);
  const [tempQuestion, setTempQuestion] = useState('');
  const [tempAnswer, setTempAnswer] = useState('');

  const fetchTestCase = async () => {
    setLoading(true);
    try {
      const response = await api.get(`${TEST_CASES_URL}${id}/`);
      setQuestion(response.data.question);
      setExpectedAnswer(response.data.expected_answer);
      setTempQuestion(response.data.question);
      setTempAnswer(response.data.expected_answer);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: 'Er is iets misgegaan bij het ophalen van het testvraag.',
      });
      navigate('/backoffice/testcases');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTestCase();
  }, [id]);

  const handleSaveQuestion = async () => {
    if (!tempQuestion.trim()) {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: 'Je moet een vraag invullen!',
      });
      return;
    }

    setSaving(true);
    try {
      await api.put(`${TEST_CASES_URL}${id}/`, {
        question: tempQuestion,
        expected_answer: expectedAnswer,
      });
      setQuestion(tempQuestion);
      setEditingQuestion(false);
      Swal.fire({
        icon: 'success',
        title: 'Gelukt!',
        text: 'De vraag is succesvol opgeslagen.',
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: 'Er is iets misgegaan bij het opslaan van de vraag.',
      });
    } finally {
      setSaving(false);
    }
  };

  const handleSaveAnswer = async () => {
    if (!tempAnswer.trim()) {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: 'Je moet een verwacht antwoord invullen!',
      });
      return;
    }

    setSaving(true);
    try {
      await api.put(`${TEST_CASES_URL}${id}/`, {
        question,
        expected_answer: tempAnswer,
      });
      setExpectedAnswer(tempAnswer);
      setEditingAnswer(false);
      Swal.fire({
        icon: 'success',
        title: 'Gelukt!',
        text: 'Het antwoord is succesvol opgeslagen.',
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: 'Er is iets misgegaan bij het opslaan van het antwoord.',
      });
    } finally {
      setSaving(false);
    }
  };

  const handleGenerateAnswer = async () => {
    if (!question) {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: 'Er is geen vraag beschikbaar om een antwoord voor te genereren!',
      });
      return;
    }

    setGenerating(true);
    try {
      const response = await api.post(GENERATE_TEST_ANSWER, { question });
      setTempAnswer(response.data.answer);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: 'Er is iets misgegaan bij het genereren van het antwoord.',
      });
    } finally {
      setGenerating(false);
    }
  };

  const handleRunTest = async () => {
    setRunning(true);
    try {
      const response = await api.post(`${TEST_CASES_URL}${id}/run/`);
      navigate(`/backoffice/testresultaat/${response.data.result}`);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: 'Er is iets misgegaan bij het uitvoeren van de test.',
      });
      setRunning(false);
    }
  };

  const handleCancelQuestion = () => {
    setTempQuestion(question);
    setEditingQuestion(false);
  };

  const handleCancelAnswer = () => {
    setTempAnswer(expectedAnswer);
    setEditingAnswer(false);
  };

  const handleDeleteTest = async () => {
    // Show confirmation dialog first
    const result = await Swal.fire({
      title: 'Weet je het zeker?',
      text: 'Wil je deze testvraag echt verwijderen? Dit kan niet ongedaan worden gemaakt.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Ja, verwijderen',
      cancelButtonText: 'Annuleren',
    });

    if (result.isConfirmed) {
      setDeleting(true);
      try {
        await api.del(`${TEST_CASES_URL}${id}/`);
        Swal.fire(
          'Verwijderd!',
          'De testvraag is succesvol verwijderd.',
          'success',
        );
        navigate('/backoffice/testcases');
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oeps...',
          text: 'Er is iets misgegaan bij het verwijderen van de testvraag.',
        });
        setDeleting(false);
      }
    }
  };

  return (
    <Container sx={{ my: 6 }} className="testcase-detail-view">
      <Backdrop
        className="loading-backdrop"
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="page-header">
        <BackofficeTitle title="Test Vraag Details" icon={<IconBook />} />
        <div className="actions">
          <button
            type="button"
            className="btn btn-ghost"
            onClick={() => navigate('/backoffice/testcases')}
          >
            Terug naar Test Vragen
          </button>
          <Button
            type="button"
            className="btn btn-red"
            onClick={handleDeleteTest}
            disabled={deleting}
          >
            {deleting && <IconLoader2 className="loader" />}
            <IconTrash size={18} />
            Verwijderen
          </Button>
          <button
            type="button"
            className="btn btn-green"
            onClick={handleRunTest}
            disabled={running || saving}
          >
            {running && <IconLoader2 className="loader" />}
            Test Uitvoeren
          </button>
        </div>
      </div>

      <div className="testcase-form">
        <div className="form-header">
          <h2>Test Vraag Bewerken</h2>
        </div>
        <div className="form-content">
          <div className="form-group">
            <div className="field-header">
              <label>Vraag</label>
              {!editingQuestion && (
                <button
                  type="button"
                  className="edit-button"
                  onClick={() => setEditingQuestion(true)}
                >
                  <IconEdit />
                  Bewerken
                </button>
              )}
            </div>
            {editingQuestion ? (
              <div className="edit-container">
                <div className="input-wrapper">
                  <textarea
                    value={tempQuestion}
                    onChange={(e) => setTempQuestion(e.target.value)}
                    placeholder="Voer de testvraag in..."
                  />
                </div>
                <div className="button-group">
                  <button
                    type="button"
                    className="btn btn-ghost"
                    onClick={handleCancelQuestion}
                  >
                    Annuleren
                  </button>
                  <button
                    type="button"
                    className="btn btn-green"
                    onClick={handleSaveQuestion}
                    disabled={saving}
                  >
                    {saving && <IconLoader2 className="loader" />}
                    Opslaan
                  </button>
                </div>
              </div>
            ) : (
              <div className="content-display">
                {question}
              </div>
            )}
          </div>

          <div className="form-group">
            <div className="field-header">
              <label>Verwacht antwoord</label>
              {!editingAnswer && (
                <button
                  type="button"
                  className="edit-button"
                  onClick={() => setEditingAnswer(true)}
                >
                  <IconEdit />
                  Bewerken
                </button>
              )}
            </div>
            {editingAnswer ? (
              <div className="edit-container">
                <div className="answer-header">
                  <button
                    type="button"
                    className="generate-button"
                    onClick={handleGenerateAnswer}
                    disabled={generating}
                  >
                    {generating && <IconLoader2 className="loader" />}
                    Genereer Antwoord
                  </button>
                </div>
                <div className="input-wrapper">
                  <textarea
                    value={tempAnswer}
                    onChange={(e) => setTempAnswer(e.target.value)}
                    placeholder="Voer het verwachte antwoord in of gebruik de Genereer knop..."
                  />
                </div>
                <div className="button-group">
                  <button
                    type="button"
                    className="btn btn-ghost"
                    onClick={handleCancelAnswer}
                  >
                    Annuleren
                  </button>
                  <button
                    type="button"
                    className="btn btn-green"
                    onClick={handleSaveAnswer}
                    disabled={saving}
                  >
                    {saving && <IconLoader2 className="loader" />}
                    Opslaan
                  </button>
                </div>
              </div>
            ) : (
              <div className="content-display">
                <ReactMarkdown>{expectedAnswer}</ReactMarkdown>
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default TestCaseDetailView;
