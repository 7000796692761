import React, { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container, Button, Box, Grid,
} from '@mui/material';
import Swal2 from 'sweetalert2';
import { IconTag } from '@tabler/icons-react';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { TOPICS_URL } from '../../../../Constants/URLS';
import BackofficeTitle from '../../../../Components/Backoffice/BackofficeTitle';
import FormInput from '../../../../Components/Backoffice/FormInput';
import FormLabel from '../../../../Components/Backoffice/FormLabel';
import FormTextArea from '../../../../Components/Backoffice/FormTextArea';

function TopicEditView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  // Fetch the topic by ID when the component mounts
  useEffect(() => {
    const fetchTopic = async () => {
      try {
        const response = await api.get(`${TOPICS_URL}${id}/`);
        setName(response.data.name);
        setDescription(response.data.description);
      } catch (error) {
        Swal2.fire({
          title: 'Fout',
          text: `Kan onderwerp niet laden: ${error.response ? error.response.data.message : error.message}`,
          icon: 'error',
        });
      }
    };

    fetchTopic();
  }, [id]);

  const updateTopic = async () => {
    if (!name || !description) {
      Swal2.fire({
        title: 'Fout',
        text: 'Vul alle verplichte velden in.',
        icon: 'error',
      });
      return;
    }

    api.patch(`${TOPICS_URL}${id}/`, { name, description }).then(() => {
      navigate('/backoffice/onderwerpen/');
    });
  };

  const deleteTopic = async () => {
    const swal = await Swal2.fire({
      title: 'Weet je het zeker?',
      text: 'Dit kan niet ongedaan worden gemaakt!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ja, verwijder',
      cancelButtonText: 'Annuleren',
    });

    if (swal.isConfirmed) {
      api.del(`${TOPICS_URL}${id}/`).then(() => {
        navigate('/backoffice/onderwerpen/');
      });
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 6, minHeight: 'calc(100vh - 180px)' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <BackofficeTitle title="Onderwerp bewerken" icon={<IconTag />} />
        <div>
          <Button
            className="btn btn-ghost"
            onClick={() => navigate('/backoffice/onderwerpen')}
          >
            Terug
          </Button>
          <Button
            className="btn btn-destructive"
            onClick={deleteTopic}
            sx={{ ml: 1 }}
          >
            Verwijderen
          </Button>
          <Button
            className="btn btn-green"
            sx={{ ml: 1 }}
            onClick={updateTopic}
          >
            Opslaan
          </Button>
        </div>
      </Box>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
          <FormLabel label="Naam" required />
          <FormInput
            value={name}
            onChange={setName}
            limit={225}
            placeholder="Onderwerp naam..."
          />
          <FormLabel label="Beschrijving" required style={{ marginTop: 20 }} />
          <FormTextArea
            value={description}
            onChange={setDescription}
            placeholder="Onderwerp beschrijving..."
            rows={4}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default TopicEditView;
