import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconLoader2, IconBookUpload } from '@tabler/icons-react';
import { Container, Backdrop, CircularProgress } from '@mui/material';
import Swal from 'sweetalert2';
import BackofficeTitle from '../../../../Components/Backoffice/BackofficeTitle';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { TEST_CASES_URL, GENERATE_TEST_ANSWER } from '../../../../Constants/URLS';

function TestCaseNewView() {
  // Utils
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const navigate = useNavigate();

  // Form states
  const [question, setQuestion] = useState('');
  const [expectedAnswer, setExpectedAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);

  const handleSave = async () => {
    if (!question) {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: 'Je moet een vraag invullen!',
      });
      return;
    }

    if (!expectedAnswer) {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: 'Je moet een verwacht antwoord invullen!',
      });
      return;
    }

    setLoading(true);
    try {
      await api.post(TEST_CASES_URL, {
        question,
        expected_answer: expectedAnswer,
      });
      navigate('/backoffice/testcases');
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: 'Er is iets misgegaan bij het opslaan van het testvraag.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateAnswer = async () => {
    if (!question) {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: 'Je moet eerst een vraag invullen!',
      });
      return;
    }

    setGenerating(true);
    try {
      const response = await api.post(GENERATE_TEST_ANSWER, { question });
      setExpectedAnswer(response.data.answer);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: 'Er is iets misgegaan bij het genereren van het antwoord.',
      });
    } finally {
      setGenerating(false);
    }
  };

  return (
    <Container sx={{ my: 6 }} className="testcase-new-view">
      <Backdrop
        className="loading-backdrop"
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="page-header">
        <BackofficeTitle title="Voeg een testvraag toe" icon={<IconBookUpload />} />
        <div className="actions">
          <button
            type="button"
            className="btn btn-ghost"
            onClick={() => navigate('/backoffice/testcases')}
          >
            Annuleren
          </button>
          <button
            type="button"
            className="btn btn-green"
            onClick={handleSave}
            disabled={loading}
          >
            {loading && <IconLoader2 className="loader" />}
            Opslaan
          </button>
        </div>
      </div>

      <div className="testcase-form">
        <div className="form-header">
          <h2>Testvraag Details</h2>
        </div>
        <div className="form-content">
          <div className="form-group">
            <label>
              Vraag
              <span className="required">*</span>
            </label>
            <div className="input-wrapper">
              <textarea
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder="Voer de testvraag in..."
              />
            </div>
          </div>

          <div className="form-group">
            <div className="answer-header">
              <label>
                Verwacht antwoord
                <span className="required">*</span>
              </label>
              <button
                type="button"
                className="generate-button"
                onClick={handleGenerateAnswer}
                disabled={generating || !question}
              >
                {generating && <IconLoader2 className="loader" />}
                Genereer Antwoord
              </button>
            </div>
            <div className="input-wrapper">
              <textarea
                value={expectedAnswer}
                onChange={(e) => setExpectedAnswer(e.target.value)}
                placeholder="Voer het verwachte antwoord in of gebruik de Genereer knop..."
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default TestCaseNewView;
