import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Container,
  Box,
} from '@mui/material';
import {
  IconAlertTriangle,
  IconAlertTriangleFilled,
  IconMessages,
  IconCircleCheckFilled,
} from '@tabler/icons-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { CONVERSATIONS_URL } from '../../../../Constants/URLS';
import { containerStyles } from '../../../../Components/TableStyles/TableStyles';
import './ConversationTableView.scss';
import BackofficeTitle from '../../../../Components/Backoffice/BackofficeTitle';
import TableSelectLabel from '../../../../Components/Backoffice/TableSelectLabel';
import Checkbox from '../../../../Components/Backoffice/Checkbox';

// Default values for pagination
const DEFAULT_PAGE = 0; // 0-based index for MUI TablePagination
const DEFAULT_ROWS_PER_PAGE = 25;

function ConversationTableView() {
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // --- State Initialization from URL ---
  const initialPage = parseInt(searchParams.get('page') || String(DEFAULT_PAGE), 10);
  const initialRowsPerPage = parseInt(
    searchParams.get('pageSize') || String(DEFAULT_ROWS_PER_PAGE),
    10,
  );
  const initialOrder = searchParams.get('order') || 'desc';
  const initialOrderBy = searchParams.get('orderBy') || 'created_at';
  const initialSearch = searchParams.get('search') || '';
  const initialAlertFilter = searchParams.getAll('alertFilter') || [];
  const initialAssignedToMe = searchParams.get('assignedToMe') === 'true';

  const [conversations, setConversations] = useState([]);
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [order, setOrder] = useState(initialOrder);
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [search, setSearch] = useState(initialSearch);
  const [totalCount, setTotalCount] = useState(0);
  const MAX_TOPICS_LENGTH = 40;
  const [assignedToMe, setAssignedToMe] = useState(initialAssignedToMe);
  const [alertFilter, setAlertFilter] = useState(initialAlertFilter);

  // Ref to track if it's the initial mount
  const isInitialMount = useRef(true);

  const formatTopics = (topics) => {
    if (topics.length < MAX_TOPICS_LENGTH) return topics;
    return `${topics.slice(0, MAX_TOPICS_LENGTH)}...`;
  };

  const alertOptions = [
    { text: 'Waarschuwing', value: 'escalated' },
    { text: 'Geen waarschuwing', value: 'no_escalation' },
    { text: 'Opgelost', value: 'solved_escalation' },
  ];

  const updateSearchParams = useCallback(
    (newParams) => {
      const currentParams = new URLSearchParams(searchParams);
      Object.entries(newParams).forEach(([key, value]) => {
        if (
          value === null
          || value === undefined
          || value === ''
          || (Array.isArray(value) && value.length === 0)
        ) {
          currentParams.delete(key);
        } else if (Array.isArray(value)) {
          currentParams.delete(key);
          value.forEach((item) => currentParams.append(key, item));
        } else {
          currentParams.set(key, String(value));
        }
      });
      // Only update if params actually changed to prevent unnecessary history entries
      if (currentParams.toString() !== searchParams.toString()) {
        setSearchParams(currentParams, { replace: true });
      }
    },
    [searchParams, setSearchParams],
  );

  const fetchConversations = useCallback(async () => {
    const apiPage = page + 1;
    const apiPageSize = rowsPerPage;
    const apiOrdering = `${order === 'desc' ? '-' : ''}${orderBy}`;
    const apiHasEscalation = alertFilter.length === 1 ? alertFilter[0] : null;
    const apiUser = searchParams.get('user');
    const apiAssignedToMe = assignedToMe || null;

    try {
      const response = await api.get(CONVERSATIONS_URL, {
        params: {
          page: apiPage,
          page_size: apiPageSize,
          ordering: apiOrdering,
          search: search || null,
          has_escalation: apiHasEscalation,
          user: apiUser,
          assigned_to_me: apiAssignedToMe,
        },
      });
      setConversations(response.data.results);
      setTotalCount(response.data.count);
    } catch (error) {
      console.error('Failed to fetch conversations:', error);
      setConversations([]);
      setTotalCount(0);
    }
  }, [
    api,
    page,
    rowsPerPage,
    order,
    orderBy,
    search,
    alertFilter,
    assignedToMe,
    searchParams, // Keep searchParams if 'user' is needed
  ]);

  // Effect to fetch data when relevant state changes
  useEffect(() => {
    fetchConversations();
  }, [fetchConversations]);

  // Effect to update URL when state changes
  useEffect(() => {
    // Don't update the URL on the very first render,
    // as the state is already derived from the URL.
    // Let subsequent state changes update the URL.
    if (!isInitialMount.current) {
      updateSearchParams({
        page,
        pageSize: rowsPerPage,
        order,
        orderBy,
        search,
        alertFilter,
        assignedToMe: assignedToMe ? 'true' : null, // Store boolean as string or null
      });
    }
  }, [
    page,
    rowsPerPage,
    order,
    orderBy,
    search,
    alertFilter,
    assignedToMe,
    updateSearchParams, // Include updateSearchParams in dependencies
  ]);

  // Effect to reset page to 0 ONLY when filters/sorting/search change AFTER initial mount
  useEffect(() => {
    if (isInitialMount.current) {
      // On initial mount, just set the ref to false and do nothing else
      isInitialMount.current = false;
    } else {
      // On subsequent renders (caused by dependency changes), reset the page
      // Only reset if the page isn't already 0
      setPage(DEFAULT_PAGE);
    }
  }, [rowsPerPage, order, orderBy, search, alertFilter, assignedToMe]); // Dependencies are correct

  // --- Event Handlers ---
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    // Page reset is handled by the effect above
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // Page reset is handled by the effect above
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    // Page reset is handled by the effect above
  };

  const handleAlertFilterChange = (newFilterValue) => {
    if (newFilterValue.length === 3) {
      setAlertFilter([]);
    } else {
      setAlertFilter(newFilterValue);
    }
    // Page reset is handled by the effect above
  };

  const handleAssignedToMeChange = () => {
    setAssignedToMe(!assignedToMe);
    // Page reset is handled by the effect above
  };

  // --- Render ---
  return (
    <Container maxWidth="lg" sx={containerStyles} className="conversations-view">
      {/* ... (rest of the JSX remains the same) ... */}
      <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
        <BackofficeTitle title="Gesprekken" icon={<IconMessages />} />
        <input
          placeholder="Zoek..."
          value={search}
          onChange={handleSearchChange}
          className="backoffice-search"
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Checkbox
          onChange={handleAssignedToMeChange}
          checked={assignedToMe}
          id="assigned-to-me"
        >
          Toegewezen aan mij
        </Checkbox>
      </Box>
      <TableContainer className="backoffice-table">
        <Table>
          <TableHead className="table-header">
            <TableRow>
              <TableCell>
                <TableSelectLabel
                  onChange={handleAlertFilterChange}
                  options={alertOptions}
                  value={alertFilter}
                >
                  <IconAlertTriangle />
                </TableSelectLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'user__full_name'}
                  direction={orderBy === 'user__full_name' ? order : 'asc'}
                  onClick={() => handleRequestSort('user__full_name')}
                >
                  Naam
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'user__email'}
                  direction={orderBy === 'user__email' ? order : 'asc'}
                  onClick={() => handleRequestSort('user__email')}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'created_at'}
                  direction={orderBy === 'created_at' ? order : 'asc'}
                  onClick={() => handleRequestSort('created_at')}
                >
                  Datum en tijd
                </TableSortLabel>
              </TableCell>
              <TableCell className="center">Berichten</TableCell>
              <TableCell>Onderwerpen</TableCell>
              <TableCell>Toegewezen aan</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {conversations.length === 0 && (
              <TableRow className="no-results-table-row">
                <TableCell colSpan={7}>Geen resultaten gevonden</TableCell>
              </TableRow>
            )}
            {conversations.map((conversation) => {
              // Determine the escalation icon outside the JSX return
              let escalationIcon = null;
              if (
                conversation.escalation
                && conversation.escalation.length > 0
              ) {
                const hasUnhandled = conversation.escalation.some(
                  (esc) => !esc.handled,
                );
                if (hasUnhandled) {
                  escalationIcon = (
                    <IconAlertTriangleFilled className="alert-icon" />
                  );
                } else {
                  // All escalations are handled
                  escalationIcon = (
                    <IconCircleCheckFilled className="handled-icon" />
                  );
                }
              }

              return (
                <TableRow
                  key={conversation.id}
                  className="table-row"
                  onClick={() => navigate(`/backoffice/gesprek/${conversation.id}`)}
                >
                  <TableCell>{escalationIcon}</TableCell>
                  <TableCell>
                    {conversation.user?.full_name
                    || conversation.user?.email
                    || 'Onbekend Gebruiker'}
                  </TableCell>
                  <TableCell>{conversation.user.email}</TableCell>
                  <TableCell>
                    {new Date(conversation.created_at).toLocaleString([], {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </TableCell>
                  <TableCell className="center">
                    {conversation.messages_count}
                  </TableCell>
                  <TableCell>
                    {formatTopics(conversation.topics.join(', '))}
                  </TableCell>
                  <TableCell>{conversation.assigned_to}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          labelRowsPerPage="Rijen per pagina:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} van ${count !== -1 ? count : `meer dan ${to}`}`}
        />
      </TableContainer>
    </Container>
  );
}

export default ConversationTableView;
