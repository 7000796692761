import React, {
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TablePagination,
  TableSortLabel, Container, Box, Button,
} from '@mui/material';
import {
  IconTestPipe,
} from '@tabler/icons-react';
import Swal from 'sweetalert2';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { TEST_RESULTS_URL, TEST_CASES_URL } from '../../../../Constants/URLS';
import {
  containerStyles,
  tablePaginationStyles,
} from '../../../../Components/TableStyles/TableStyles';
import BackofficeTitle from '../../../../Components/Backoffice/BackofficeTitle';
import DutchDate from '../../../../Components/DutchDate/DutchDate';

function TestResultsTableView() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const [testResults, setTestResults] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [filter, setFilter] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  // Polling related state
  const previousResultsRef = useRef([]);
  const noChangesCountRef = useRef(0);
  const pollingInterval = useRef(null);

  const handleRequestSort = (property) => {
    if (property === orderBy) {
      if (order === 'asc') {
        setOrder('desc');
      } else {
        setOrderBy('');
      }
    } else {
      setOrderBy(property);
      setOrder('asc');
    }
  };

  const handleRequestFilter = (property) => {
    if (property === filterBy) {
      if (filter === 'asc') {
        setFilter('desc');
      } else {
        setFilterBy('');
      }
    } else {
      setFilterBy(property);
      setFilter('asc');
    }
  };

  const fetchTestResults = async () => {
    let params = `?page=${page}&page_size=${rowsPerPage}`;
    if (filterBy && filter) {
      params += `&${filterBy}__${filter === 'desc' ? 'lt' : 'gte'}=1`;
    }
    if (orderBy) {
      params += `&ordering=${order === 'desc' ? '-' : ''}${orderBy}`;
    }

    const response = await api.get(`${TEST_RESULTS_URL}${params}`);

    // Compare with previous results for polling
    const newResults = response.data.results;
    const prevResults = previousResultsRef.current;

    if (JSON.stringify(newResults) === JSON.stringify(prevResults)) {
      noChangesCountRef.current += 1;
    } else {
      noChangesCountRef.current = 0;
      previousResultsRef.current = newResults;
    }

    // Stop polling if no changes for 2 intervals
    if (noChangesCountRef.current >= 2 && pollingInterval.current) {
      clearInterval(pollingInterval.current);
      pollingInterval.current = null;

      // Remove polling parameter from URL
      const newParams = new URLSearchParams(searchParams);
      newParams.delete('polling');
      navigate({ search: newParams.toString() }, { replace: true });
    }

    setTestResults(newResults);
    setTotalCount(response.data.count);
  };

  const handleRunAll = async () => {
    const response = await api.post(`${TEST_CASES_URL}run-all/`);
    if (response.status === 200) {
      // Add polling parameter and navigate
      const newParams = new URLSearchParams(searchParams);
      newParams.set('polling', 'true');
      navigate({
        pathname: '/backoffice/testresultaten',
        search: newParams.toString(),
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: 'Er is iets misgegaan bij het uitvoeren van de test vragen.',
      });
    }
  };

  // Setup polling when requested
  useEffect(() => {
    const pollingParam = searchParams.get('polling');
    if (pollingParam === 'true' && !pollingInterval.current) {
      // Reset tracking state
      previousResultsRef.current = [];
      noChangesCountRef.current = 0;

      // Start polling
      pollingInterval.current = setInterval(fetchTestResults, 5000);
    }

    return () => {
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current);
      }
    };
  }, [searchParams]);

  // Regular data fetching
  useEffect(() => {
    fetchTestResults();
  }, [page, rowsPerPage, order, orderBy, filter, filterBy]);

  useEffect(() => {
    setPage(1);
  }, [order, orderBy]);

  const truncateText = (text, maxLength = 100) => {
    if (!text) return '';
    if (text.length <= maxLength) return text;
    return `${text.substring(0, maxLength)}...`;
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        ...containerStyles,
        minHeight: 'calc(100vh - 300px)',
      }}
      className="test-results-table-view"
    >
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <BackofficeTitle title="Test Resultaten" icon={<IconTestPipe />} />
        <Button
          onClick={() => navigate('/backoffice/testcases')}
          className="btn btn-ghost"
          sx={{ marginLeft: 'auto' }}
        >
          Test Vragen
        </Button>
        <Button
          onClick={() => handleRunAll()}
          className="btn btn-green"
        >
          Alle Tests Uitvoeren
        </Button>
      </Box>

      <TableContainer className="backoffice-table">
        <Table>
          <TableHead className="table-header">
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'is_match'}
                  direction={orderBy === 'is_match' ? order : 'desc'}
                  onClick={() => handleRequestSort('distance')}
                >
                  Resultaat
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={filterBy === 'sources_count'}
                  direction={filterBy === 'sources_count' ? filter : 'desc'}
                  onClick={() => handleRequestFilter('sources_count')}
                >
                  Geen Antwoord
                </TableSortLabel>
              </TableCell>
              <TableCell>
                Vraag
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'created_at'}
                  direction={orderBy === 'created_at' ? order : 'asc'}
                  onClick={() => handleRequestSort('created_at')}
                >
                  Gemaakt Op
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testResults.map((result) => (
              <TableRow
                key={result.id}
                className="table-row"
                hover
                onClick={() => navigate(`/backoffice/testresultaat/${result.id}`)}
                style={{ cursor: 'pointer' }}
              >
                <TableCell className="score-cell">
                  <span className={`score-badge ${result.is_match ? 'high-score' : 'low-score'}`}>
                    {result.is_match ? 'Correct' : 'Incorrect'}
                  </span>
                </TableCell>
                <TableCell className="table-cell">
                  {result.sources_count < 1 ? 'Ja' : 'Nee'}
                </TableCell>
                <TableCell className="table-cell">
                  {truncateText(result.test_case_question)}
                </TableCell>
                <TableCell className="light">
                  <DutchDate date={result.created_at} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount}
        page={page - 1}
        onPageChange={(event, newPage) => setPage(newPage + 1)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(1);
        }}
        labelRowsPerPage="Rijen per pagina:"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} van ${count !== -1 ? count : `meer dan ${to}`}`}
        style={tablePaginationStyles}
      />
    </Container>
  );
}

export default TestResultsTableView;
