import React, { useState, useEffect, useContext } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box,
  Container, DialogTitle, DialogContent, Button, Dialog,
} from '@mui/material';
import {
  IconCheck, IconLoader,
  IconMailForward, IconSend, IconTrash,
} from '@tabler/icons-react';
import Swal from 'sweetalert2';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { containerStyles } from '../../Components/TableStyles/TableStyles';
import BackofficeTitle from '../../Components/Backoffice/BackofficeTitle';
import { INVITATION_URL } from '../../Constants/URLS';
import TableSelectLabel from '../../Components/Backoffice/TableSelectLabel';
import FormInput from '../../Components/Backoffice/FormInput';
import FormLabel from '../../Components/Backoffice/FormLabel';
import DutchDate from '../../Components/DutchDate/DutchDate';

function InvitationsView() {
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);

  const [invitations, setInvitations] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState('');
  const [used, setUsed] = useState([]);

  const [invitationEmail, setInvitationEmail] = useState('');
  const [invitationModalOpen, setInvitationModalOpen] = useState(false);

  const usedOptions = [
    { value: 'true', text: 'Ja' },
    { value: 'false', text: 'Nee' },
  ];

  const fetchInvitations = async () => {
    try {
      let params = `?page=${page + 1}&page_size=${rowsPerPage}`;
      if (search) {
        params += `&search=${search}`;
      }
      if (used.length) {
        params += used.map((u) => `&used=${u}`).join('');
      }

      const response = await api.get(`${INVITATION_URL}${params}`);
      setInvitations(response.data.results);
      setTotalCount(response.data.count);
    } catch (error) {
      console.error('Error fetching FAQs:', error);
    }
  };

  useEffect(() => {
    fetchInvitations();
  }, [page, rowsPerPage, search, used]);

  useEffect(() => {
    setPage(0);
  }, [rowsPerPage, search, used]);

  useEffect(() => {
    setInvitationEmail('');
  }, [invitationModalOpen]);

  const sendInvitation = async () => {
    try {
      await api.post(INVITATION_URL, { email: invitationEmail });
      setInvitationModalOpen(false);
      fetchInvitations();
      Swal.fire({
        title: 'Uitnodiging verstuurd',
        text: `De uitnodiging voor ${invitationEmail} is verstuurd.`,
        icon: 'success',
      });
    } catch (error) {
      console.error('Error sending invitation:', error);
      Swal.fire({
        title: 'Fout bij versturen',
        text: 'Er is iets fout gegaan bij het versturen van de uitnodiging.',
        icon: 'error',
      });
    }
  };

  const deleteInvitation = async (invitation) => {
    const swal = await Swal.fire({
      title: 'Bevestigen',
      text: `Weet je zeker dat je de uitnodiging voor ${invitation.email} wilt verwijderen?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nee',
    });
    if (!swal.isConfirmed) {
      return;
    }

    api.del(`${INVITATION_URL}${invitation.id}/`).then(() => {
      fetchInvitations();
      Swal.fire({
        title: 'Uitnodiging verwijderd',
        text: `De uitnodiging voor ${invitation.email} is verwijderd.`,
        icon: 'success',
      });
    });
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        ...containerStyles,
        minHeight: 'calc(100vh - 300px)',
      }}
      className="invitations-view"
    >
      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <BackofficeTitle title="Uitnodigingen" icon={<IconMailForward />} />
        <input
          placeholder="Zoek..."
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
            setPage(0);
          }}
          className="backoffice-search"
        />
        <Button
          onClick={() => setInvitationModalOpen(true)}
          startIcon={<IconSend />}
          className="btn btn-green"
        >
          Uitnodiging versturen
        </Button>
      </Box>

      <TableContainer className="backoffice-table">
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableHead className="table-header">
            <TableRow>
              <TableCell sx={{ width: '5%' }}>
                ID
              </TableCell>
              <TableCell>
                Email
              </TableCell>
              <TableCell>
                Code
              </TableCell>
              <TableCell sx={{ width: '7%' }}>
                <TableSelectLabel
                  options={usedOptions}
                  value={used}
                  onChange={setUsed}
                >
                  Gebruikt
                </TableSelectLabel>
              </TableCell>
              <TableCell>
                Gemaakt op
              </TableCell>
              <TableCell>
                Gemaakt door
              </TableCell>
              <TableCell>
                Verwijderen
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invitations.map((invitation) => (
              <TableRow key={invitation.id} hover>
                <TableCell>
                  {`#${invitation.id}`}
                </TableCell>
                <TableCell>
                  {invitation.email}
                </TableCell>
                <TableCell>
                  <code>{invitation.access_code}</code>
                </TableCell>
                <TableCell>
                  <span className={`used-chip ${invitation.used ? 'used' : ''}`}>
                    {invitation.used ? <IconCheck /> : <IconLoader />}
                    {invitation.used ? 'Ja' : 'Nee'}
                  </span>
                </TableCell>
                <TableCell>
                  <DutchDate date={invitation.created_at} />
                </TableCell>
                <TableCell>
                  {invitation.created_by || '-'}
                </TableCell>
                <TableCell>
                  <Button
                    startIcon={<IconTrash />}
                    className="btn btn-ghost delete-invitation"
                    onClick={() => deleteInvitation(invitation)}
                  >
                    Verwijderen
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
          labelRowsPerPage="Rijen per pagina:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} van ${count !== -1 ? count : `meer dan ${to}`}`}
        />
      </TableContainer>

      <Dialog
        open={invitationModalOpen}
        onClose={() => setInvitationModalOpen(false)}
        maxWidth="sm"
        fullWidth
        className="new-faq-dialog"
      >
        <DialogTitle>
          Nieuwe uitnodiging
        </DialogTitle>
        <DialogContent>
          <FormLabel label="Email" required />
          <FormInput
            onChange={setInvitationEmail}
            value={invitationEmail}
            placeholder="Voer een emailadres in..."
          />
          <Button
            className="btn btn-green"
            sx={{ mt: 2 }}
            disabled={!invitationEmail}
            onClick={sendInvitation}
          >
            Uitnodiging versturen
          </Button>
          <Button
            onClick={() => setInvitationModalOpen(false)}
            className="btn btn-ghost"
            sx={{ mt: 2, ml: 1 }}
          >
            Annuleren
          </Button>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default InvitationsView;
