import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Paper,
  Button,
} from '@mui/material';
import {
  IconFileText,
  IconTestPipe,
} from '@tabler/icons-react';
import ReactMarkdown from 'react-markdown';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { TEST_RESULTS_URL } from '../../../../Constants/URLS';
import BackofficeTitle from '../../../../Components/Backoffice/BackofficeTitle';

function TestResultView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const [testResult, setTestResult] = useState(null);

  useEffect(() => {
    const fetchTestResult = async () => {
      try {
        const response = await api.get(`${TEST_RESULTS_URL}${id}/`);
        setTestResult(response.data);
      } catch (error) {
        console.error('Error fetching test result:', error);
      }
    };

    fetchTestResult();
  }, [id]);

  const handleSourceClick = (source) => {
    navigate(`/backoffice/bronnen/${source.source.id}`);
  };

  if (!testResult) return null;

  return (
    <Container maxWidth="lg" className="test-result-detail-view">
      <Box sx={{
        display: 'flex',
        gap: 2,
        mb: 2,
        alignItems: 'center',
      }}
      >
        <BackofficeTitle title="Test Resultaat" icon={<IconTestPipe />} />
      </Box>

      <Paper className="content-container">
        <Box className="header-section">
          <span className={`score-badge ${testResult.is_match ? 'high-score' : 'low-score'}`}>
            {testResult.is_match ? 'Correct' : 'Incorrect'}
          </span>
          <Button
            onClick={() => navigate('/backoffice/testresultaten')}
            className="btn btn-ghost"
            sx={{ marginLeft: 'auto' }}
          >
            Terug naar Overzicht
          </Button>
          <Button
            onClick={() => navigate(`/backoffice/testcase/${testResult.test_case.id}`)}
            className="btn btn-green"
          >
            Bekijk Test Vraag
          </Button>
        </Box>

        <div className="section">
          <h3>Vraag</h3>
          <div className="content-box">
            <ReactMarkdown>{testResult.test_case.question}</ReactMarkdown>
          </div>
        </div>

        <div className="section-row">
          <div className="section sources-section">
            <h3>Bronnen</h3>
            <div className="content-box">
              {testResult.sources.length > 0 ? (
                <div className="sources-list">
                  {testResult.sources.map((source, index) => (
                    // eslint-disable-next-line
                    <div
                      key={index}
                      className="source-item"
                      onClick={() => handleSourceClick(source)}
                      role="button"
                      tabIndex={0}
                    >
                      <IconFileText className="source-icon" />
                      <span>{source.source.title}</span>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="no-sources">Geen bronnen beschikbaar</p>
              )}
            </div>
          </div>
        </div>

        <div className="section-row answers-section">
          <div className="section">
            <h3>Gegeven Antwoord</h3>
            <div className="content-box">
              <ReactMarkdown>{testResult.answer}</ReactMarkdown>
            </div>
          </div>

          <div className="section">
            <h3>Verwacht Antwoord</h3>
            <div className="content-box">
              <ReactMarkdown>{testResult.test_case.expected_answer}</ReactMarkdown>
            </div>
          </div>
        </div>
      </Paper>
    </Container>
  );
}

export default TestResultView;
