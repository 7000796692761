import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Container, Grid, Chip, Box,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  IconAlertTriangleFilled, IconLogs, IconUserMinus, IconUserPlus,
} from '@tabler/icons-react';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import {
  ASSIGNMENT_URL, AUDIT_LOG_URL, COMMENTS_URL, CONVERSATIONS_URL, ESCALATIONS_URL, USER_URL,
} from '../../../../Constants/URLS';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import ConversationMessage from '../../../../Components/Backoffice/ConversationMessage';
import OptionsPopover from '../../../../Components/Backoffice/OptionsPopover';

export default function ConversationView() {
  const { id } = useParams();
  const [conversation, setConversation] = useState(null);
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const navigate = useNavigate();
  const [newComment, setNewComment] = useState('');

  const unhandledEscalations = conversation && conversation.escalation.filter((e) => !e.handled);

  const [users, setUsers] = useState([]);
  const [usersSearch, setUsersSearch] = useState('');
  const [selectedUser, setSelectedUser] = useState([]);
  const [anchorElAssign, setAnchorElAssign] = useState(null);
  const openAssign = Boolean(anchorElAssign);
  const [usersCount, setUsersCount] = useState(0);

  const [auditLogs, setAuditLogs] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const lastAssignment = assignments.length > 0 && assignments.slice().reverse()[0];

  useEffect(() => {
    api.get(USER_URL, {
      params: {
        search: usersSearch,
      },
    }).then((response) => {
      setUsers(response.data.results);
      setUsersCount(response.data.count);
    });
  }, [usersSearch]);

  useEffect(() => {
    if (lastAssignment) {
      if (lastAssignment.assigned_to) {
        setSelectedUser([lastAssignment.assigned_to_id]);
      } else {
        setSelectedUser([]);
      }
    }
  }, [lastAssignment]);

  const fetchAssignments = () => {
    api.get(ASSIGNMENT_URL, {
      params: {
        conversation: id,
      },
    }).then((response) => {
      setAssignments(response.data);
    });
  };

  const fetchAuditLogs = () => {
    api.get(`${AUDIT_LOG_URL}?conversation=${id}`).then((response) => {
      setAuditLogs(response.data);
    });
  };

  const handleAssign = (u) => {
    if (lastAssignment && lastAssignment.assigned_to && u === lastAssignment.assigned_to_id) {
      api.post(ASSIGNMENT_URL, {
        content_type: 'conversation',
        object_id: conversation.id,
        assigned_to: null,
      }).then(() => {
        fetchAssignments();
        setAnchorElAssign(null);
      });
      return;
    }
    api.post(ASSIGNMENT_URL, {
      content_type: 'conversation',
      object_id: conversation.id,
      assigned_to: u,
    }).then(() => {
      fetchAssignments();
      setAnchorElAssign(null);
    });
  };

  const fetchConversation = () => {
    api.get(`${CONVERSATIONS_URL}${id}`).then((response) => {
      setConversation(response.data);
    }).catch(() => {
      navigate('/backoffice/gesprekken');
    });
  };

  useEffect(() => {
    fetchConversation();
    fetchAssignments();
    fetchAuditLogs();
  }, []);

  const addComment = () => {
    api.post(COMMENTS_URL, {
      conversation: conversation.id,
      text: newComment,
    }).then(() => {
      fetchConversation();
      setNewComment('');
    });
  };

  const solveEscalation = () => {
    api.patch(`${ESCALATIONS_URL}${unhandledEscalations[0].id}/`, {
      handled: true,
    }).then(() => {
      fetchConversation();
      fetchAuditLogs();
    });
  };

  const previousAssignment = (assignment) => {
    const index = assignments.indexOf(assignment);
    if (index === 0) {
      return null;
    }
    return assignments[index - 1];
  };

  return conversation && (
    <Container className="conversation-view" sx={{ mt: 1 }}>
      {unhandledEscalations.length > 0 && (
        <div className="alert">
          <h4>
            <IconAlertTriangleFilled />
            {' '}
            Gesprekprobleem gedetecteerd
            {lastAssignment && lastAssignment.assigned_to && (
              <div className="assignment-label">
                Toegewezen aan
                {' '}
                <b>{lastAssignment.assigned_to}</b>
              </div>
            )}
          </h4>
          <p>
            Er is handmatige assistentie vereist met betrekking tot het bericht &quot;
            {conversation.messages.find((m) => m.id === unhandledEscalations[0].message).message}
            &quot;.
          </p>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Button
                className="btn btn-warning"
                onClick={solveEscalation}
              >
                Oplossen
              </Button>
              <Button
                className="btn btn-ghost"
              >
                Kijk in het gesprek
              </Button>
            </Box>
            <Button
              className="btn btn-ghost"
              onClick={(e) => setAnchorElAssign(e.currentTarget)}
            >
              Toewijzen aan...
            </Button>
            <OptionsPopover
              open={openAssign}
              anchorEl={anchorElAssign}
              closeMenu={() => setAnchorElAssign(null)}
              options={users.map((user) => ({
                text: user.full_name,
                value: user.id,
              }))}
              value={selectedUser}
              onChange={handleAssign}
              count={usersCount}
              search={usersSearch}
              onSearchChange={setUsersSearch}
            />
          </Box>
        </div>
      )}
      <Grid container spacing={6} pt={4} pb={9}>
        <Grid item xs={12} lg={4}>
          <div className="conversation-panel">
            <div className="panel-header">
              <h2>
                {conversation.user.full_name}
              </h2>
              <span className="timestamps">
                {moment(conversation.created_at).format('DD/MM/YYYY HH:mm')}
                {' — '}
                {moment(conversation.last_message_time).format('DD/MM/YYYY HH:mm')}
              </span>
            </div>
            <div className="panel-content">
              <p>
                Onderwerpen:
                {' '}
                {conversation.topics.map((topic) => (
                  <Chip key={topic} label={topic} size="small" />
                ))}
              </p>
              <p>
                Email:
                {' '}
                <a href={`mailto:${conversation.user.email}`}>{conversation.user.email}</a>
              </p>
              {conversation.user.phone_number && (
                <p>
                  Telefoon:
                  {' '}
                  <a href={`tel:${conversation.user.phone_number}`}>{conversation.user.phone_number}</a>
                </p>
              )}
              {conversation.user.office_name && (
                <p>
                  Bedrijf:
                  {' '}
                  {conversation.user.office_name}
                </p>
              )}
              {conversation.user.position_role && (
                <p>
                  Functietitel:
                  {' '}
                  {conversation.user.position_role}
                </p>
              )}
            </div>
          </div>
          <div className="conversation-panel">
            <div className="panel-header">
              <h2>
                Audit Log
              </h2>
            </div>
            <div className="panel-content">
              <div>
                {assignments.map((assignment) => (
                  <div className="log">
                    <div className="log-icon">
                      <span>
                        {assignment.assigned_to ? <IconUserPlus /> : <IconUserMinus />}
                      </span>
                    </div>
                    <div className="log-content">
                      <b>{assignment.created_by}</b>
                      {' '}
                      {assignment.assigned_to ? 'heeft' : 'heeft de toewijzing van'}
                      {' '}
                      <b>{assignment.assigned_to || previousAssignment(assignment).assigned_to}</b>
                      {' '}
                      {assignment.assigned_to ? 'toegewezen.' : 'verwijderd.'}
                      {' '}
                      <small>
                        {moment(assignment.created_at).format('DD.MM.YYYY HH:mm')}
                      </small>
                    </div>
                  </div>
                ))}
                {auditLogs.map((log) => (
                  <div className="log">
                    <div className="log-icon">
                      <span>
                        <IconLogs />
                      </span>
                    </div>
                    <div className="log-content">
                      <b>{log.user_first_name}</b>
                      {' '}
                      {log.text}
                      {' '}
                      <small>
                        {moment(log.created_at).format('DD.MM.YYYY HH:mm')}
                      </small>
                    </div>
                  </div>
                ))}
              </div>
              <h4 className="comments-title">
                Opmerkingen
                <span className="badge">
                  {conversation.comments.length}
                </span>
              </h4>
              {conversation.comments.length === 0 && (
                <p className="comments-placeholder">Geen opmerkingen</p>
              )}
              {conversation.comments.map((comment) => (
                <div className="comment">
                  <div className="comment-header">
                    <span className="author">{comment.user.full_name}</span>
                    <span className="time">{moment(comment.created_at).format('DD/MM/YYYY, HH:mm')}</span>
                  </div>
                  <p>
                    {comment.text}
                  </p>
                </div>
              ))}
              <div className="new-comment">
                <label>
                  Nieuwe opmerking
                </label>
                <textarea
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  placeholder="Voer hier een opmerking in"
                  rows={3}
                />
                <Button
                  className="btn btn-green"
                  sx={{ mt: 2 }}
                  onClick={addComment}
                >
                  Opslaan
                </Button>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={8}>
          <div className="chat">
            {conversation.messages.map((message) => (
              <ConversationMessage
                message={message}
                sender={message.role === 'assistant' ? 'Chatbot' : conversation.user.first_name}
              />
            ))}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
