import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { TbArrowRight, TbTrash } from 'react-icons/tb';
import Swal from 'sweetalert2';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import { useMatches } from '../../Providers/MatchesProvider/MatchesProvider';
import { MATCHES_URL } from '../../Constants/URLS';

function FoundMatch({ match, showSoftDelete }) {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const { fetchMatches } = useMatches();

  const navigateToMatch = (clientId, rankingSimilarity) => {
    navigate('/klant-match', { state: { clientId, rankingSimilarity } });
  };

  const softDeleteMatch = async (event, matchId) => {
    event.preventDefault();
    event.stopPropagation();

    Swal.fire({
      title: 'Verwijderen',
      text: `Weet je zeker dat je de match ${match.case_study.first_name} wilt verwijderen?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nee',
    }).then(async (result) => {
      if (result.isDismissed) {
        return;
      }
      try {
        api.post(`${MATCHES_URL}/${matchId}/soft_delete/`).then(() => {
          fetchMatches();
          Swal.fire({
            title: 'Verwijderd',
            text: `De match ${match.case_study.first_name} is verwijderd`,
            icon: 'success',
          }).then(() => {
            window.location.reload();
          });
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error soft deleting the match:', error);
      }
    });
  };

  // Format the created_at date and time
  const formattedDateTime = new Intl.DateTimeFormat('nl-NL', {
    dateStyle: 'long',
    timeStyle: 'short',
  }).format(new Date(match.created_at));

  return (
    // eslint-disable-next-line
    <div
      className="match-item"
      onClick={() => navigateToMatch(match.client.id, match.ranking_similarity)}
      role="button"
    >
      <div className="match-item-details">
        <div
          className="match-item-image"
          style={{
            backgroundImage: `url(${match.case_study.image_link?.image})`,
          }}
        />
        <div className="match-details">
          <h3>
            {match.case_study.first_name}
          </h3>
          <h4>
            {match.case_study.occupation.job_role}
            {' - '}
            {match.match_age}
            {' '}
            jaar oud
          </h4>
          <h4>
            {formattedDateTime}
          </h4>
        </div>
      </div>
      <div className="button-group">
        {showSoftDelete && (
          <button
            type="button"
            onClick={(e) => softDeleteMatch(e, match.id)}
            className="soft-delete-button"
            aria-label="Overeenkomst verwijderen"
          >
            <TbTrash />
          </button>
        )}
        <button
          type="button"
          onClick={() => navigateToMatch(match.client.id, match.ranking_similarity)}
          className="details-button"
          aria-label="Bekijk wedstrijddetails"
        >
          <TbArrowRight />
        </button>
      </div>
    </div>
  );
}

FoundMatch.propTypes = {
  match: PropTypes.shape({
    id: PropTypes.string.isRequired,
    match_age: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    ranking_similarity: PropTypes.number.isRequired,
    case_study: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      image_link: PropTypes.shape({
        image: PropTypes.string,
      }),
      occupation: PropTypes.shape({
        job_role: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    client: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  showSoftDelete: PropTypes.bool,
};

FoundMatch.defaultProps = {
  showSoftDelete: false,
};

export default FoundMatch;
