import React, {
  useContext, useReducer, useState, useEffect,
} from 'react';
import {
  Button, CircularProgress, Container, IconButton, InputAdornment, TextField,
} from '@mui/material';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { TbCircleCheckFilled, TbCircleXFilled, TbClipboardPlus } from 'react-icons/tb';
import Swal from 'sweetalert2';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import SignUpApis from '../../APIs/SignUpApis';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import signupReducer from './SignUpReducer';

function SignupView() {
  const userContext = useContext(UserContext);
  const SignUpAPIs = SignUpApis(userContext);
  const { tokens } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [jobRoles, setJobRoles] = useState([]);
  const navigate = useNavigate();
  const [form, formDispatch] = useReducer(signupReducer, {
    firstName: '',
    infix: '',
    familyName: '',
    positionRole: '',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    emailError: '',
    phoneError: '',
    firstNameError: '',
    familyNameError: '',
    positionRoleError: '',
    passwordError: '',
    confirmPasswordError: '',
    accessCode: '',
    accessCodeError: '',
    termsAndConditions: false,
    canSubmit: false,
    submitting: false,
    officeName: '',
  });

  const [passwordCharacters, setPasswordCharacters] = useState(false);
  const [passwordDigits, setPasswordDigits] = useState(false);
  const [passwordUppercase, setPasswordUppercase] = useState(false);
  const [passwordSpecialCharacter, setPasswordSpecialCharacter] = useState(false);

  const [searchParams] = useSearchParams();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (searchParams.has('email')) {
      formDispatch({ type: 'EMAIL', payload: searchParams.get('email') });
    }
    if (searchParams.has('code')) {
      formDispatch({ type: 'ACCESS_CODE', payload: searchParams.get('code') });
    }
  }, [searchParams]);

  useEffect(() => {
    if (form.password.length >= 8) {
      setPasswordCharacters(true);
    } else {
      setPasswordCharacters(false);
    }
    if (form.password.match(/[0-9]/)) {
      setPasswordDigits(true);
    } else {
      setPasswordDigits(false);
    }
    if (form.password.match(/[A-Z]/)) {
      setPasswordUppercase(true);
    } else {
      setPasswordUppercase(false);
    }
    if (form.password.match(/[^a-zA-Z0-9]/)) {
      setPasswordSpecialCharacter(true);
    } else {
      setPasswordSpecialCharacter(false);
    }
  }, [form.password]);

  useEffect(() => {
    // Fetch job roles from the backend
    SignUpAPIs.getJobRoles()
      .then((response) => {
        setJobRoles(response.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching job roles', error);
      });
  }, []);

  function trySignup() {
    formDispatch({ type: 'SUBMIT', payload: true });
    setLoading(true);

    if (!form.termsAndConditions) {
      formDispatch({
        type: 'HANDLE_ERROR',
        payload: { fullFieldError: 'Je moet akkoord gaan met de gebruikersvoorwaarden en de privacyverklaring.' },
      });
      setLoading(false);
      return;
    }

    const signupData = {
      first_name: form.firstName,
      infix: form.infix,
      last_name: form.familyName,
      position_role: form.positionRole,
      email: form.email,
      phone_number: form.phoneNumber,
      password1: form.password,
      password2: form.confirmPassword,
      office_name: form.officeName,
      access_code: form.accessCode,
    };

    SignUpAPIs.postSignup(signupData)
      .then((signupResponse) => {
        // eslint-disable-next-line
        if (signupResponse?.status === 201 || signupResponse?.status === 200 || signupResponse?.status === 204) {
          // Handle successful signup, e.g., display a confirmation message
          // eslint-disable-next-line no-alert
          Swal.fire({
            icon: 'success',
            title: 'Gelukt',
            text: 'Je account is aangemaakt. Je kunt nu inloggen.',
            confirmButtonText: 'Close',
          }).then(() => {
            navigate('/inloggen');
          });
        } else {
          // Handle unexpected signup response
          // eslint-disable-next-line no-console
          console.error('Signup response unexpected', signupResponse);
        }
        setLoading(false);
      }).catch((signupError) => {
      // Handle signup error
      // eslint-disable-next-line no-console
        console.error('Signup failed', signupError);
        formDispatch({ type: 'HANDLE_ERROR', payload: signupError.response?.data || {} });
        setLoading(false);
        if (signupError.response?.data?.password1) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: signupError.response.data.password1[0],
            confirmButtonText: 'Close',
          });
        } else if (form.fullFieldError) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: form.fullFieldError,
            confirmButtonText: 'Close',
          });
        }
      });

    // Validate the access code before attempting to sign up
    // SignUpAPIs.validateAccessCode(form.email, form.accessCode)
    //   .then((response) => {
    //     if (response.status === 200) {
    //       // Access code is valid, proceed with signup
    //
    //     } else {
    //       // Handle invalid access code
    //  formDispatch({ type: 'HANDLE_ERROR', payload: { accessCodeError: 'Invalid access code' } });
    //       setLoading(false);
    //     }
    //   })
    //   .catch((validateError) => {
    //     // Handle error during access code validation
    //     // eslint-disable-next-line no-console
    //     console.error('Access code validation error', validateError);
    // const errorMessage = validateError.response?.data?.message || 'Error validating access code';
    //     // eslint-disable-next-line no-alert
    //     Swal.fire({
    //       icon: 'error',
    //       title: 'Error',
    //       text: errorMessage,
    //       confirmButtonText: 'Close',
    //     });
    //     formDispatch({ type: 'HANDLE_ERROR', payload: { accessCodeError: errorMessage } });
    //     setLoading(false);
    //   });
  }

  useEffect(() => {
    if (form.canSubmit) trySignup();
  }, [form.canSubmit]);

  return (
    <>
      <div className="authentication-view">
        <Container maxWidth="lg" sx={{ pt: 5, pb: 15 }}>
          <a href="/">
            <img src="/default-platform-logo.png" alt="logo" className="logo" />
          </a>
        </Container>
        {tokens?.accessToken === undefined || tokens.accessToken.length === 0 ? (
          <Container maxWidth="sm" sx={{ pb: 9 }}>
            <div className="panel login-form signup-form">
              {loading ? (
                <CircularProgress color="inherit" sx={{ height: 'inherit' }} />
              ) : (
                <>
                  <h1>
                    Registreren
                    <span>.</span>
                  </h1>
                  <p>
                    Wil je een account, maar heb je nog geen activatiecode?
                    {' '}
                    Neem dan contact op met
                    {' '}
                    <span className="email-link">ao@creditlife.nl</span>
                  </p>
                  <label>
                    Voornaam
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Bijv. Jeroen"
                    value={form.firstName}
                    autoComplete="given-name"
                    onChange={(e) => formDispatch({ type: 'FIRST_NAME', payload: e.target.value })}
                  />

                  <label>Tussenvoegsel</label>
                  <input
                    type="text"
                    placeholder="Bijv. van der"
                    value={form.infix}
                    autoComplete="off"
                    onChange={(e) => formDispatch({ type: 'INFIX', payload: e.target.value })}
                  />
                  <label>
                    Achternaam
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Bijv. Berg"
                    value={form.familyName}
                    autoComplete="last-name"
                    onChange={(e) => formDispatch({ type: 'FAMILY_NAME', payload: e.target.value })}
                  />

                  <label>
                    Beroep
                    <span className="required">*</span>
                  </label>
                  <select
                    onChange={(e) => formDispatch({ type: 'POSITION_ROLE', payload: e.target.value })}
                  >
                    <option value="" disabled selected>
                      Selecteer een beroep
                    </option>
                    {jobRoles.map((role) => (
                      <option key={role.value} value={role.label}>
                        {role.label}
                      </option>
                    ))}
                  </select>

                  <label>
                    Kantoornaam
                  </label>
                  <input
                    type="text"
                    placeholder="Bijv. Credit Life"
                    value={form.officeName}
                    onChange={(e) => formDispatch({ type: 'OFFICE_NAME', payload: e.target.value })}
                  />

                  <label>
                    Telefoonnummer
                    <span className="required">*</span>
                  </label>
                  <input
                    type="tel"
                    placeholder="Bijv. +31612345678"
                    value={form.phoneNumber}
                    onChange={(e) => formDispatch({ type: 'PHONE_NUMBER', payload: e.target.value })}
                  />

                  <label>
                    Emailadres
                    <span className="required">*</span>
                  </label>
                  <input
                    type="email"
                    placeholder="Bijv. jeroen.berg@creditlife.nl"
                    value={form.email}
                    autoComplete="email"
                    onChange={(e) => formDispatch({ type: 'EMAIL', payload: e.target.value })}
                  />

                  <label>
                    Wachtwoord
                    <span className="required">*</span>
                  </label>
                  <TextField
                    type={showPassword ? 'text' : 'password'}
                    value={form.password}
                    onChange={(e) => formDispatch({ type: 'PASSWORD', payload: e.target.value })}
                    placeholder="Geef een wachtwoord op..."
                    className="password-input"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <IconEyeOff /> : <IconEye />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {form.password.length > 0 && (
                    <>
                      <div className="password-strength" style={{ marginTop: 15 }}>
                        <span className={passwordCharacters ? 'valid' : 'not-valid'}>
                          {passwordCharacters ? <TbCircleCheckFilled /> : <TbCircleXFilled />}
                        </span>
                        Ten minste 8 tekens
                      </div>
                      <div className="password-strength">
                        <span className={passwordUppercase ? 'valid' : 'not-valid'}>
                          {passwordUppercase ? <TbCircleCheckFilled /> : <TbCircleXFilled />}
                        </span>
                        Ten minste één hoofdletter
                      </div>
                      <div className="password-strength">
                        <span className={passwordDigits ? 'valid' : 'not-valid'}>
                          {passwordDigits ? <TbCircleCheckFilled /> : <TbCircleXFilled />}
                        </span>
                        Ten minste één cijfer
                      </div>
                      <div className="password-strength">
                        <span className={passwordSpecialCharacter ? 'valid' : 'not-valid'}>
                          {passwordSpecialCharacter ? <TbCircleCheckFilled /> : <TbCircleXFilled />}
                        </span>
                        Ten minste één speciaal teken
                      </div>
                    </>
                  )}

                  <label>
                    Bevestig wachtwoord
                    <span className="required">*</span>
                  </label>
                  <TextField
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={form.confirmPassword}
                    onChange={(e) => formDispatch({ type: 'CONFIRM_PASSWORD', payload: e.target.value })}
                    placeholder="Bevestig wachtwoord..."
                    className="password-input"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            edge="end"
                          >
                            {showConfirmPassword ? <IconEyeOff /> : <IconEye />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {form.password !== form.confirmPassword && (
                    <div className="password-strength" style={{ marginTop: 15 }}>
                      <span className="not-valid">
                        <TbCircleXFilled />
                      </span>
                      Wachtwoorden komen niet overeen.
                    </div>
                  )}
                  <label>
                    Activatiecode
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Jouw activatiecode..."
                    value={form.accessCode}
                    onChange={(e) => formDispatch({ type: 'ACCESS_CODE', payload: e.target.value })}
                  />

                  <input
                    type="checkbox"
                    id="terms-and-conditions"
                    checked={form.termsAndConditions}
                    onChange={(e) => formDispatch({ type: 'TERMS_AND_CONDITIONS', payload: e.target.checked })}
                  />
                  <label htmlFor="terms-and-conditions" className="terms-field">
                    Ik ga akkoord met de
                    {' '}
                    <a href="/gebruikersvoorwaarden" aria-label="gebruikersvoorwaarden">gebruikersvoorwaarden</a>
                    {' '}
                    en de
                    {' '}
                    <a href="/privacyverklaring" aria-label="privacyverklaring">privacyverklaring</a>
                    .
                    <span className="required">*</span>
                  </label>

                  <div className="form-actions">
                    <Button
                      onClick={() => formDispatch({ type: 'VALIDATE' })}
                      className="btn btn-green"
                      startIcon={<TbClipboardPlus />}
                      disabled={loading}
                    >
                      Registreren
                    </Button>
                    <a
                      href="/inloggen"
                    >
                      Heb je al een account?
                      {' '}
                      <b>Hier inloggen.</b>
                    </a>
                  </div>
                </>
              )}
            </div>
          </Container>
        ) : (
          <Navigate to={{ pathname: '/' }} />
        )}
      </div>
    </>
  );
}

export default SignupView;
