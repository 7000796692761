import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button, Container, Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import './NavigationBar.scss';
import Swal from 'sweetalert2';
import { TbDeviceDesktopAnalytics, TbHourglass, TbPower } from 'react-icons/tb';
import { FaUserCircle } from 'react-icons/fa';
import { IconCalculator, IconLayout } from '@tabler/icons-react';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import BackofficeNavbar from '../BackofficeNavbar';
import logUserAction from '../LogUserActions/LogUserActions';
import apiUtils from '../../Utils/ApiUtils';

function NavigationBar({ pages }) {
  const { user, logout } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const api = apiUtils();

  // Determine if the navigation bar should be rendered
  const shouldRenderNavBar = Object.values(pages).some((page) => page.showMenu
    && location.pathname.includes(page.path)) || location.pathname === '/klant-match' || location.pathname.includes('backoffice');

  if (!shouldRenderNavBar) {
    return null;
  }

  const isBackoffice = location.pathname.includes('backoffice');

  const handleLogout = () => {
    Swal.fire({
      title: 'Weet je zeker dat je wilt uitloggen?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ja, uitloggen',
      cancelButtonText: 'Nee, blijf ingelogd',
    }).then((result) => {
      if (result.isConfirmed) {
        logUserAction(user.id, 'Logout', api);
        logout();
        navigate('/');
      }
    });
  };

  return (
    <>
      <div className="custom-navbar">
        <Container>
          <div className="custom-navbar__container">
            <div className="custom-navbar__logo">
              <a href="/">
                <img src="/logo.png" alt="logo" />
              </a>
            </div>
            {user && user.email && (
              <>
                <div className="custom-navbar__menu">
                  {Object.keys(pages).map((key) => {
                    const page = pages[key];
                    const hasAccess = !page.roles || page.roles.includes(user.role);
                    if (page.showInMenu && hasAccess) {
                      const isActive = location.pathname === page.path;
                      const navName = page.navName || key.charAt(0).toUpperCase() + key.slice(1).replace(/\s+$/, '');
                      return (
                        <Button
                          key={page.id}
                          className={`custom-navbar__menu-item ${isActive && 'active'}`}
                          startIcon={page.menuIcon}
                          onClick={() => navigate(page.path)}
                        >
                          {navName}
                        </Button>
                      );
                    }
                    return null;
                  })}
                  <Button
                    className="custom-navbar__menu-item"
                    href="https://mycreditlife.nl/login"
                    startIcon={<IconCalculator />}
                    target="_blank"
                  >
                    Rekentool
                  </Button>
                  <Button
                    className="custom-navbar__menu-item"
                    href="https://www.creditlife.nl/arbeidsongeschiktheid-toolkit-voor-adviseur/"
                    startIcon={<IconLayout />}
                    target="_blank"
                  >
                    Toolkit
                  </Button>
                </div>
                <div className="custom-navbar__user">
                  <Tooltip title="Geschiedenis" placemen="top" arrow>
                    <Button
                      className="custom-navbar__user-item"
                      href="/matches"
                    >
                      <TbHourglass />
                    </Button>
                  </Tooltip>
                  {user && pages.data_dashboard.roles.includes(user.role) && (
                    <Tooltip title="Backoffice" placemen="top" arrow>
                      <Button
                        onClick={() => navigate(pages.data_dashboard.path)}
                        className="custom-navbar__user-item"
                      >
                        <TbDeviceDesktopAnalytics />
                      </Button>
                    </Tooltip>
                  )}
                  <Tooltip title="Uitloggen" placemen="top" arrow>
                    <Button
                      className="custom-navbar__user-item"
                      onClick={handleLogout}
                    >
                      <TbPower />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Account" placemen="top" arrow>
                    {/* eslint-disable-next-line */}
                  <div
                    className="custom-navbar__user-details"
                    role="button"
                    onClick={() => navigate('/profiel')}
                  >
                    <div className="profile-img">
                      <FaUserCircle />
                    </div>
                    <div>
                      <span>
                        {user.first_name}
                        {' '}
                        {user.infix && `${user.infix} `}
                        {user.last_name}
                      </span>
                      {user.email}
                    </div>
                  </div>
                  </Tooltip>
                </div>
              </>
            )}
          </div>
        </Container>
      </div>
      {isBackoffice && (
        <BackofficeNavbar />
      )}
    </>
  );
}

NavigationBar.propTypes = {
  pages: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      path: PropTypes.string.isRequired,
      showInMenu: PropTypes.bool,
      showMenu: PropTypes.bool,
      navName: PropTypes.string,
      menuIcon: PropTypes.element,
      roles: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
};

export default NavigationBar;
